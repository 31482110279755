import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import menuConfig from '@configs/menu/menu.json';
import { IMenuSection, MenuSection } from '../models/menu/menu-section.model';
import { IMenu, MenuType } from '../models/menu/menu.model';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    menu$: IMenu;
    sections$: BehaviorSubject<MenuSection[]>;

    constructor() {
        this.menu$ = {
            mobile: new BehaviorSubject(false),
            desktop: new BehaviorSubject(true)
        }

        const menuSections = (<IMenuSection[]>menuConfig.sections)
            .map((section: IMenuSection) => new MenuSection(section));
        this.sections$ = new BehaviorSubject(menuSections);
    }

    get mobileMenu$() {
        return this.menu$.mobile;
    }

    get desktopMenu$() {
        return this.menu$.desktop;
    }

    openMenu(isOpen: boolean, menuType: MenuType = 'mobile') {
        this.menu$[menuType].next(isOpen);
    }

    toggleMenu(menuType: MenuType) {
        this.menu$[menuType].next(!this.menu$[menuType].getValue())
    }


}