import moment from "moment";
import { deepClone } from '@vedrai/vedrai-core';
import { intersection, union } from 'lodash';
import { CompareTimeSeriesType, SeriesType, TimeSeries } from "../..";

export class TimeSeriesJoiner<T, K extends SeriesType>{

    get timeSeries() {
        return deepClone(this._timeSeries);
    }

    private set timeSeries(timeSeries) {
        this._timeSeries = timeSeries;
    }

    private _timeSeries: TimeSeries<T>[] = [];
    private dates: string[] = [];
    private storedTimeSeries: TimeSeries<T>[] = [];

    private compareTimeSeriesType: CompareTimeSeriesType;

    constructor(compareTimeSeriesType: CompareTimeSeriesType) {
        this.compareTimeSeriesType = compareTimeSeriesType;
    }

    addTimeSeriesList(timeSeriesList: TimeSeries<T>[]) {
        timeSeriesList.forEach((timeSeries) => this.addTimeSeries(timeSeries));
    }

    addTimeSeries(timeSeries: TimeSeries<T>) {
        this.storeTimeSeries(timeSeries);
        this.calculateDatesAndValues();
    }

    removeTimeSeries(timeSeries: TimeSeries<T>) {
        const seriesIndex = this.storedTimeSeries.findIndex(({ entity }) => entity['id'] == timeSeries.entity['id']);
        this.storedTimeSeries.splice(seriesIndex, 1);

        this.calculateDatesAndValues();
    }

    private calculateDatesAndValues() {
        this.dates = this.calculateDates(this.compareTimeSeriesType);

        this._timeSeries = this.storedTimeSeries.map((timeSeries) => {
            return this.calculateTimeSeriesSeries(deepClone(timeSeries));
        })
    }

    private calculateTimeSeriesSeries(timeSeries: TimeSeries<T>) {
        timeSeries.series.forEach((series: K) => {
            series.values = this.calculateValues(timeSeries.dates, series.values);
        });
        timeSeries.dates = this.dates;

        return timeSeries;
    }

    private calculateValues(seriesDates: string[], values) {
        let result = [];

        this.dates.forEach((date, index) => {
            const value = seriesDates.includes(date) ? values[index] : null;
            result.push(value);
        });

        return result;
    }

    private storeTimeSeries(timeSeries: TimeSeries<T>) {
        this.storedTimeSeries.push(timeSeries);
    }

    private calculateDates(compareTimeSeriesType: CompareTimeSeriesType): string[] {
        switch (compareTimeSeriesType) {
            case 'union':
                return this.datesUnion();
            case 'intersection':
                return this.datesIntersection();
            default:
                throw ('CompareTimeSeriesType not found');
        }
    }

    private datesUnion(): string[] {
        let unionDates = [];
        this.storedTimeSeries.forEach(({ dates }) => unionDates = union(unionDates, dates));
        return this.sortDates(unionDates);
    }

    private datesIntersection(): string[] {
        let intersectionDates = [];
        this.storedTimeSeries.forEach(({ dates }) => intersectionDates = intersection(intersectionDates, dates));
        return this.sortDates(intersectionDates);
    }

    private sortDates(dates: string[]): string[] {
        return dates.sort((a, b) => Math.round(moment(a).diff(moment(b), 'd')) <= 0 ? -1 : 1);
    }

}