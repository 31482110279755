import { tap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { DataRouteService } from '@vedrai/vedrai-core';
import { filter, map } from 'rxjs/operators';
import { DataRoute } from '../../vendors/modules/vedrai-kit/models/data-route/data-route.model';
import { BreadcrumbsService } from './breadcrumbs.service';
import { Observable } from 'rxjs';
import { BreadCrumbGroup } from '../models/bread-crumb/bread-crumb-group.model';

export function routesInitializer(router: Router, activatedRoute: ActivatedRoute,
    dataRouteService: DataRouteService, breadcrumbsService: BreadcrumbsService) {
    return (): Promise<any> => {

        const navigationEvents$ = setNavigationInLoading(router.events, breadcrumbsService);
        const onActivatedRoute$ = onActivatedRoute(navigationEvents$, activatedRoute);

        onActivatedRoute$.pipe(
            map((route: ActivatedRoute) => setRouterHelpers(route))
        ).subscribe(({ routesData, breadCrumbGroup }) => {
            dataRouteService.dataRoute = routesData.data;
            breadcrumbsService.addBreadcrumbGroup(breadCrumbGroup);
        });

        return new Promise((resolve) => resolve(true));
    };
}

function onActivatedRoute(navigationEvents$: Observable<any>, activatedRoute: ActivatedRoute) {
    return navigationEvents$.pipe(
        filter((event: any) => event instanceof NavigationEnd),
        map(() => activatedRoute)
    );
}

function setNavigationInLoading(navigationEvents$: Observable<any>,
    breadcrumbsService: BreadcrumbsService) {

    return navigationEvents$.pipe(tap((event: any) => {

        if (event instanceof NavigationStart) {
            breadcrumbsService.inLoading(true);
        }

        if (event instanceof NavigationEnd) {
            breadcrumbsService.inLoading(false)
        }
    }))
}

function setRouterHelpers(route: ActivatedRoute) {

    const breadCrumbGroup: BreadCrumbGroup = new BreadCrumbGroup();
    const routesData: DataRoute = new DataRoute();

    while (route.firstChild) {
        route = route.firstChild;

        breadCrumbGroup.addBreadCrumb(route);

        routesData.updateData(route);
    }

    return { routesData, breadCrumbGroup };
}