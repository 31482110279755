import { INotification } from './notification.model';
import { Notification } from './notification.model';

export class ActionNotification extends Notification {

    // title: string;
    // description: string;
    // notify: string;

    constructor(notification: INotification) {
        super(notification);
    }
}