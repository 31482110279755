import { Injectable } from '@angular/core';
import { deepClone, get } from '@vedrai/vedrai-core';

import targetsCharts from '@configs/charts/charts/targets.json';
import variablesCharts from '@configs/charts/charts/variables.json';
import scenariosCharts from '@configs/charts/charts/scenarios.json';
import sentimentsCharts from '@configs/charts/charts/sentiments.json';
import scenarioAnalysisCharts from '@configs/charts/charts/scenario-analysis.json';
import benchmarkCharts from '@configs/charts/charts/benchmark.json';
import { chartsThemes } from '../models/charts/charts-themes.model';

export type ChartsType = 'targets' | 'variables' | 'scenarios' | 'sentiments' | 'scenarioAnalysis' | 'benchmark';

@Injectable()
export class ChartsService {

    get targets(): any {
        return targetsCharts;
    }

    get variables(): any {
        return variablesCharts;
    }

    get scenarios(): any {
        return scenariosCharts;
    }

    get sentiments(): any {
        return sentimentsCharts;
    }

    get scenarioAnalysis(): any {
        return scenarioAnalysisCharts;
    }

    get benchmark(): any{
        return benchmarkCharts;
    }

    getChart(type: ChartsType, path: string, ChartClass?) {
        const chartConfig = deepClone(get(this[type], path));
        return ChartClass ? new ChartClass(chartConfig) : chartConfig;
    }

    static getThemeColors(themeName: string) {
        return deepClone(chartsThemes.find((theme) => theme.name == themeName)?.theme?.theme.color || []);
    }

}