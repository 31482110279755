import { ISeriesDate, Series } from "./series.model";
import { TimeSeriesEmpty } from './time-series-empty.model';

export interface ITimeSeries {
    series: { [key in string]: string };
    data: ISeriesDate[];
    entity: any;
}

export interface IParsedTimeSeries {
    dates: string[];
    paramsKeys: string[];
    timeSeries: { [key in string]: string };
    variableNames: { [key in string]: string };
}

export class TimeSeries<T> extends TimeSeriesEmpty<T> {

    dates: string[];
    series: Series[];
    entity: T;

    constructor(entity: T, timeSeries: ITimeSeries) {
        super();
        this.isError = false;

        this.dates = [];
        this.series = [];
        this.entity = entity;
        if (!timeSeries) return;

        const parsedTimeseries = this.parseTimeSeries(timeSeries);
        this.dates = parsedTimeseries.dates;
        this.series = this.initSeries(parsedTimeseries);
    }

    protected parseTimeSeries(timeSeries: ITimeSeries): IParsedTimeSeries {
        const { series: variableNames, data } = timeSeries;

        const paramsKeys: string[] = Object.keys(variableNames);
        const newTimeSeries = paramsKeys.reduce((res, key) => (res[key] = [], res), {});
        const dates = [];

        data.forEach(({ date, series }) => {

            dates.push(date);

            paramsKeys.forEach((param) => {
                newTimeSeries[param].push(
                    typeof series[param] == 'number' ?
                        series[param] : null
                );
            });
        });

        return { dates, paramsKeys, timeSeries: newTimeSeries, variableNames };
    }

    protected initSeries(parsedTimeSeries: IParsedTimeSeries) {
        const { paramsKeys, timeSeries } = parsedTimeSeries;
        return paramsKeys.map((param: string) => new Series({
            id: param,
            values: timeSeries[param]
        }))
    }
}