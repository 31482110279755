import { getFormattedDate } from '@shared';
import { TimeSeries } from '@vendors/modules/ngx-echarts';
import { ExcelTemplateOptions } from './excel-template-options.model';

export type AcceptedSeriesType = { id: string, label: string };

export class TimeSeriesToExcel<T> {

    timeSeries: TimeSeries<T>[];
    acceptedSeries: AcceptedSeriesType[];

    headers: string[];
    rows: any[][];

    get firstTimeSeries() {
        return this.timeSeries[0];
    }

    // TODO pass an array of accepted Series like [{id: 'real', label: 'reale'}, {...}]
    constructor(timeSeries: TimeSeries<T>[], options: ExcelTemplateOptions) {

        this.timeSeries = timeSeries;
        this.acceptedSeries = options.acceptedSeries;

        this.headers = this.getHeaders(options.entityRowKey);
        this.rows = this.getRows();
    }

    private getHeaders(entityRowKey: string) {
        let headers = [' '];
        this.timeSeries.forEach(({ entity, series }: any) => {
            const columns = this.getColumns(series);
            columns.map((column: any) => {
                headers = headers.concat(`${entity[entityRowKey]}(${entity.unit_meas}) - ${column.columnLabel}`);
            })
        });
        return headers;
    }

    private getRows() {

        const result = [];

        this.timeSeries.forEach(({ series, dates }: TimeSeries<T>, tsIndex: number) => {

            const tsResult = [], columns = this.getColumns(series);
            dates.forEach((date: string, index) => {

                const seriesValues = columns.map(column => column.values[index] ?? '-');
                if (!tsIndex) {
                    tsResult.push([
                        getFormattedDate(date, 'dd-MM-yyyy'),
                        ...seriesValues
                    ])
                } else {
                    tsResult.push(seriesValues)
                }
            })

            result.push(tsResult);
        });


        return result.slice(1).reduce((res, tsResult) =>
            res.map((r, index) => r.concat(tsResult[index])),
            result[0]);
    }

    private getColumns(series: any[]) {

        // TODO remove after refactoring
        if (!isNaN(+series[0]?.id)) return series.map((series) => ({ 
            ...series, columnLabel: series.label 
        }));

        return this.acceptedSeries.map(({ id, label }) => ({
            ...series.find((series) => series.id == id),
            columnLabel: label
        }));
    }
}