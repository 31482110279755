<skltn-root>
  <div class="table-container">
    <table class="table" [class]="tableClass">
      <thead>
        <th *ngFor="let col of columns">
          <div skltn-bone class="header-column skltn-card-line mb-0"></div>
        </th>
      </thead>
      <tbody>
        <tr *ngFor="let row of rows">
          <td *ngFor="let col of columns">
            <div skltn-bone class="skltn-card-line w-100 mb-0"></div>
          </td>
      </tbody>
    </table>
  </div>
</skltn-root>