import { Chart } from "@vendors/modules/ngx-echarts";
import { Filters } from "../../entities/variable/filters.model";

export class FiltersChart extends Chart {

    selectedFilter: Filters;
    data: Filters;

    get parent() {
        return this.data;
    }

    get firstChilden() {
        return Array.isArray(this.parent) ? this.parent : this.parent.children;
    }

    constructor(chart) {
        super(chart);
        this.tooltip.formatter = (params) => 'Filtro: ' + params.data.display_name;
    }

    protected treeSearchPath(target: string, node: Filters) {
        let found, { id, children = [] } = node;

        if (children.length)
            for (let child of node.children)
                if (found = this.treeSearchPath(target, child))
                    return [node].concat(found);

        return id === target && [node];
    }

    protected treeSearch(target: string, node: Filters) {
        let found, { id, children = [] } = node;

        if (children.length)
            for (let child of node.children)
                if (found = this.treeSearch(target, child))
                    return found;

        return id === target && node;
    }

}