import { BasicNotification } from './basic-notification.model';
import { INotification } from './notification.model';


export class ModalNotification extends BasicNotification {

    get icon(): string[] {
        return this.config?.icon;
    }

    get title(): string {
        return this.config?.title;
    }

    get subtitle(): string {
        return this.config?.subtitle;
    }

    get description(): string {
        return this.config?.description;
    }

    get redirectTo(): string {
        return this.value?.redirectTo;
    }

    get modalBtn(): string {
        return 'operations.' + (this.redirectTo ? 'show' : 'ok-thanks');
    }

    constructor(notification: INotification) {
        super(notification);
    }
}