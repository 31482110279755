export interface ITechAnalysis {
    intro: string;
    trend: string;
    trendsPeriods: string[];
    seasonality: string;
    end: string;
}

export interface IPeriodRange {
    max: string;
    min: string;
}

export interface IPreview extends IPeriodRange {
    trend?: string;
}

export interface ITechAnalysisPreview {
    forecast: IPreview;
    global_trend: string;
    seasonality: IPreview;
}