import { NgModule } from '@angular/core';
import { TrackByIdDirective } from './track-by-id/track-by-id.directive';
import { TrackByIndexDirective } from './track-by-index/track-by-index.directive';
import { TrackByPropertyDirective } from './track-by-property/track-by-property.directive';

@NgModule({
    declarations: [
        TrackByIdDirective,
        TrackByIndexDirective,
        TrackByPropertyDirective
    ],
    exports: [
        TrackByIdDirective,
        TrackByIndexDirective,
        TrackByPropertyDirective
    ]
})
export class DirectivesModule { }
