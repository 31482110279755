<drag-scroll #nav>
  <ng-container *ngFor="let currentSeries of series; let i = index">
    <div drag-scroll-item *ngIf="!currentSeries.hide" class="card series-card mb-3 mr-3 cursor-pointer "
      [ngStyle]="{'border-left': '4px solid ' + (currentSeries.color || '#333') }" (click)="onToggle(currentSeries)">
      <div class="card-body">
        <fa-icon [ngStyle]="{'color': currentSeries.color}" [icon]="['far', currentSeries.checked ? 'check-square' : 'square']"
          class="text-lg mr-2"></fa-icon>
        {{currentSeries.label}}
      </div>
    </div>
  </ng-container>
</drag-scroll>