export interface IShortEntity {
    id: number;
    display_name: string;
}

export class ShortEntity implements IShortEntity {
    id: number;
    display_name: string;

    constructor(entity: IShortEntity) {
        this.id = entity?.id;
        this.display_name = entity?.display_name;
    }
}