export interface ISeriesDate {
    date: string;
    series: {[key in string]: number};
}

export interface ISeries {
    id: string;
    values: any;
}

export class Series {

    id: string;
    values: any;

    constructor(series: ISeries) {
        this.id = series.id;
        this.values = series.values || [];
    }
}