import { NgModule } from '@angular/core';
import { PluralTranslatePipe } from './plural-translate/plural-translate.pipe';
import { TaggerPipe } from './tagger/tagger.pipe';

@NgModule({
    declarations: [
        PluralTranslatePipe,
        TaggerPipe
    ],
    exports: [
        PluralTranslatePipe,
        TaggerPipe
    ]
})
export class PipesModule { }
