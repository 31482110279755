import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DragScrollModule } from "ngx-drag-scroll";
import { FontAwesomeVendorModule } from "../fontawesome/fontawesome.vendor.module";

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeVendorModule,
        DragScrollModule
    ],
    exports: [
        DragScrollModule
    ]
})
export class DragScrollVendorModule { }