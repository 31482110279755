<li class="nav-item dropdown" ngbDropdown placement="bottom-right">
    <a class="nav-link dropdown-toggle" ngbDropdownToggle role="button">
        <svg viewBox="0 0 24 24" style="width: 25px; fill: white;">
            <path
                d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z">
            </path>
        </svg>
    </a>
    <div class="dropdown-menu dropdown-menu-xl shadow dropdown-menu-right" ngbDropdownMenu>
        <div *ngIf="products$ | async as products" class="row virtual-agents-list">
            <div *ngFor="let product of products" class="col-4">
                <ng-container *ngTemplateOutlet="(product.active ? enabledVA : disabledVA); context: { product }">
                </ng-container>
            </div>
        </div>
    </div>
</li>


<ng-template #enabledVA let-product="product">
    <a class="va-shortcut" (click)="navigateTo(product.code)"
        [class]="product.isCurrentProduct ? 'va-selected' : 'cursor-pointer'">
        <img class="va-logo" [class]="product.code + '_avatar'" [src]="product.logo + '#svgView(viewBox(0,0,180,135))'"
            [alt]="product.display_name + ' avatar'">
        <small [ngStyle]="{'border-bottom-color': 'var(--' + product.code + ')'}">
            {{product.display_name}}
        </small>
    </a>
</ng-template>

<ng-template #disabledVA let-product="product">
    <a class="va-shortcut disabled cursor-pointer"
        [attr.href]="'https://vedrai.com/soluzioni/' + product.display_name +'/'" target="_blank">
        <img class="va-logo" [class]="product.code + '_avatar'" [src]="product.logo + '#svgView(viewBox(0,0,180,135))'"
            [alt]="product.display_name + ' avatar'">
        <small [ngStyle]="{'border-bottom-color': 'var(--' + product.code + ')'}">
            {{product.display_name}}
        </small>
    </a>
</ng-template>