import { NgForOf } from '@angular/common';
import { Directive, Host, Input } from '@angular/core';
import { get } from '@vedrai/vedrai-core';

@Directive({
    selector: "[ngForTrackByProperty]"
})
export class TrackByPropertyDirective {

    private _propertyName: string;

    public constructor(@Host() private readonly ngFor: NgForOf<any>) {
        this.ngFor.ngForTrackBy = (index: number, item: any) => 
            this._propertyName ? get(item, this._propertyName) : item;
    }

    @Input("ngForTrackByProperty")
    public set propertyName(value: string | null) {
        this._propertyName = value ?? "";
    }
}