import { Component, Input } from '@angular/core';
import { AuthService, UserService } from '@core';
import { WebsocketService } from '@vendors/modules/ng2-stompjs';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html'
})
export class UserMenuComponent {

  @Input() mobile: boolean = false;

  menuVoices$: BehaviorSubject<any>;
  currentUser$: BehaviorSubject<any>;

  constructor(private authService: AuthService,
    private websoketService: WebsocketService,
    private userService: UserService) {
    this.currentUser$ = this.userService.currentUser$;
  }

  logout() {
    this.websoketService.disconnect();
    this.authService.logout();
  }
}
