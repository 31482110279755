import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { UtilsService } from '@vendors/modules/vedrai-kit';
import { filter, distinctUntilChanged } from 'rxjs/operators'

@Injectable({
    providedIn: 'root'
})
export class LayoutService {

    constructor(private router: Router, private utils: UtilsService) {
        this.router.events.pipe(
            filter((event: RouterEvent) => this.isConsideredEvent(event)),
            distinctUntilChanged()
        ).subscribe((event) => {
            if(this.isNavigationStart(event)){
                this.utils.inLoading(true);
            }
            if(this.isNavigationEnd(event)){
                this.utils.inLoading(false);
            }
        })
    }

    private isConsideredEvent(event: RouterEvent): boolean {
        return this.isNavigationStart(event)
            || this.isNavigationEnd(event);
    }

    private isNavigationStart(event: RouterEvent): boolean {
        return event instanceof NavigationStart;
    }

    private isNavigationEnd(event: RouterEvent): boolean {
        return event instanceof NavigationEnd
            || event instanceof NavigationCancel
            || event instanceof NavigationError;
    }
}