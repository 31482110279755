import { Workbook, Worksheet } from 'exceljs';
import { ExcelTemplateOptions } from './excel-template-options.model';
import { TimeSeriesToExcel } from './time-series-to-excel.model';



export class ExcelTemplate<T> {

    excelData: TimeSeriesToExcel<T>;
    workBook: Workbook;
    workSheet: Worksheet;

    constructor(data: any, options?: ExcelTemplateOptions) {

        // TODO use a factory to generate excelData dynamically
        this.excelData = new TimeSeriesToExcel<T>(data, options);
        this.workBook = new Workbook();
        this.workSheet = this.workBook.addWorksheet('page 1');

        this.fetchHeader();
        this.fetchRows();
        this.autoWidth();
    }

    protected fetchHeader() { }

    protected fetchRows() { }

    /**
     * Autofit columns by width
     *
     * @param minimalWidth
     */
    protected autoWidth(minimalWidth = 10) {
        this.workSheet.columns.forEach((column) => {
            let maxColumnLength = 0;
            column.eachCell({ includeEmpty: true }, (cell) => {
                maxColumnLength = Math.max(
                    maxColumnLength,
                    minimalWidth,
                    cell.value ? cell.value.toString().length : 0
                );
            });
            column.width = maxColumnLength + 2;
        });
    };
}