import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skltn-index-analysis-table',
  templateUrl: './skltn-index-analysis-table.component.html'
})
export class SkltnIndexAnalysisTableComponent {

  @Input() set columnsNumber(columnsNumber: number) {
    this.columns = [].constructor(columnsNumber);
    this.indexesColumns = [].constructor(columnsNumber - 1);
  };
  @Input() set rowsNumber(rowsNumber: number) {
    this.rows = [].constructor(rowsNumber);
  };
  @Input() tableClass: string;

  columns: number[];
  indexesColumns: number[];
  rows: number[];
}
