<footer class="footer">
  <div class="row align-items-center justify-content-xl-between">
    <div class="col-xl-6">
      <div class="copyright text-center text-xl-left text-muted">
        &copy; {{ test | date: "yyyy" }}
        <a href="https://vedrai.com/" class="font-weight-bold ml-1" target="_blank">
          {{ 'footer.company' | translate }}
        </a>
      </div>
    </div>
    <div class="col-xl-6">
      <ul class="nav nav-footer justify-content-center justify-content-xl-end">
        <li class="nav-item">
          <a href="https://vedrai.com/about/" class="nav-link"
            target="_blank">{{ 'footer.about-us' | translate }}</a>
        </li>
        <li class="nav-item">
          <a href="https://vedrai.com/soluzioni/" class="nav-link" target="_blank">
            {{ 'footer.solutions' | translate }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</footer>