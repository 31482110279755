import { NotificationsFactory } from './notification-factory.model';

// export type NotificationType = 'CTA' | 'P' | 'N';
// export type Notifications = NNotification | PNotification;

export type ExtractInstanceType<T> = T extends new () => infer R ? R : any;

export type NotificationType = keyof typeof NotificationsFactory.notifications;

export type NotificationClassType = typeof NotificationsFactory.notifications[NotificationType];

export type NotificationInstanceType = ExtractInstanceType<NotificationClassType>;