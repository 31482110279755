import { Component, EventEmitter, Output } from '@angular/core';
import { MenuService } from '../../../services/menu.service';
import { BehaviorSubject } from 'rxjs';
import { MenuVoice } from '../../../models/menu/menu-voice.model';
import { MenuSection } from '../../../models/menu/menu-section.model';
import { MenuGroup } from '../../../models/menu/menu-group.model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent {

  @Output() onSelect: EventEmitter<MenuVoice>;

  sections$: BehaviorSubject<MenuSection[]>;
  currentGroup: MenuGroup;

  constructor(private menuService: MenuService) {
    this.onSelect = new EventEmitter(null);
    this.sections$ = this.menuService.sections$;
  }

  toggleGroup(group: MenuGroup) {
    this.currentGroup = this.currentGroup == group ? null : group;
  }

  selectVoice(voice: MenuVoice) {
    this.onSelect.emit(voice);
  }

}
