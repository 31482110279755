import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from 'app/layout/layout.module';
import { HttpClientModule } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { CoreModule, AuthService } from '@core';
import { appInitializer } from 'app/app-initializer';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { DataRouteService, setAppInjector } from '@vedrai/vedrai-core';
import { ActivatedRoute, Router } from '@angular/router';

import { routesInitializer, MenuService } from '@layout';
import { PeriodsFactory } from '@models';
import { Ng2StompjsVendorModule } from '@vendors/modules/ng2-stompjs';
import { VendorsModule } from '@vendors/vendors.module';
import { BreadcrumbsService } from './layout/services/breadcrumbs.service';
import { LanguageInitializerService, LocaleProvider } from '@vendors/modules/ngx-translate';


registerLocaleData(localeIt);
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    LayoutModule,
    Ng2StompjsVendorModule,
    VendorsModule
  ],
  providers: [
    LocaleProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: routesInitializer,
      deps: [Router, ActivatedRoute, DataRouteService, BreadcrumbsService, MenuService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [LanguageInitializerService, AuthService, Injector],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    PeriodsFactory
    setAppInjector(injector);
  }
}
