export interface IChartTooltipModel {
    data: string;
    measureUnit?: string;
    marker: string;
    name: string;
    value: number | string;
}

export class ChartTooltipModel implements IChartTooltipModel {
    data: string;
    measureUnit: string;
    marker: string;
    name: string;
    value: number | string;

    constructor(model: IChartTooltipModel) {
        this.data = model.data;
        this.measureUnit = model.measureUnit;
        this.marker = model.marker;
        this.name = model.name;
        this.value = model.value;
    }
}