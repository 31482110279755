import { ExtractInstanceType, NotificationClassType, NotificationType } from './notification-types.model';
import { ActionNotification } from './notification/action-notification.model';
import { BasicNotification } from './notification/basic-notification.model';
import { ModalNotification } from './notification/modal-notification.model';
import { INotification } from './notification/notification.model';
import { ProgressNotification } from './notification/progress-notification.model';

// https://www.dofactory.com/javascript/design-patterns/factory-method
// https://medium.com/codex/factory-pattern-type-script-implementation-with-type-map-ea422f38862
export class NotificationsFactory {

    static readonly notifications = {
        CTA: ActionNotification,
        MN: ModalNotification,
        N: BasicNotification,
        P: ProgressNotification
    };

    static createNotification(message: string): ExtractInstanceType<NotificationClassType> {
        const notification = this.getNotification(message);
        return new NotificationsFactory.notifications[notification.type](notification);
    }

    private static getNotification(message: string): INotification {
        let type: NotificationType = 'N', value = {};
        try {
            const notification = JSON.parse(message);
            type = notification?.type || 'N';
            value = notification?.value || {};
        } catch (error) {
            console.error('Notification error: ' + error);
        }

        return { type, value };
    }

}