import {
  Component,
  ViewContainerRef, ViewChild, OnInit, ComponentRef
} from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-component-container-modal',
  templateUrl: './component-container-modal.component.html'
})
export class ComponentContainerModalComponent implements OnInit {

  @ViewChild('container', { static: true, read: ViewContainerRef }) container: ViewContainerRef;

  private componentRef: ComponentRef<any>;
  private data: any;

  get component() {
    return this.data.component;
  }

  get title() {
    return this.data.title;
  }

  constructor(private activeModalService: NgbActiveModal) { }

  createComponent() {
    this.container.clear();

    this.componentRef = this.container.createComponent(this.component);

    this.componentRef.instance.onDecline = this.onDecline;
    if (this.data) {
      this.componentRef.instance.initialData = this.data;
    }

  }

  ngOnInit() {
    this.createComponent();
  }

  ngOnDestroy() {
    this.componentRef.destroy();
  }

  decline() { }
  onDecline() {
    if (typeof this.decline == "function") this.decline();
    this.activeModalService.close();
  }

}