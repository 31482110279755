<div class="modal-body notification-modal">
    <div data-key="icon" class="notification-icon">
        <fa-icon [icon]="icon"></fa-icon>
    </div>

    <h2 data-key="title" class="notification-title">
        {{title | translate}}
    </h2>
    <h4 data-key="subtitle" *ngIf="subtitle" class="notification-subtitle">
        {{subtitle | translate}}
    </h4>
    <div data-key="description" *ngIf="description" class="notification-description">
        {{description | translate}}
    </div>

    <button data-key="btn" class="btn btn-primary btn-flat" (click)="onClose()">
        {{modalBtn | translate}}
    </button>
</div>