import { ITreeSelectOptions, TreeSelectOptions } from "./tree-select-options.model";
import { TreeSelect } from "./tree-select.model";
import { flatMapDeep } from 'lodash';

export class TreeSelectHelper<T> {

    private treeSelects: TreeSelect<T>[];
    private options: TreeSelectOptions;

    constructor(entities: T[], options?: ITreeSelectOptions) {
        this.options = new TreeSelectOptions(options);
        this.fetchTreeSelects(entities);
    }

    getTreeSelects() {
        return this.treeSelects;
    }

    fetchTreeSelects(entities: T[]) {
        this.treeSelects = entities.map((entity: T) => new TreeSelect(entity, this.options));
    }

    treeSearch(searchedText: string) {
        const treeSelects = this.resetTree(this.treeSelects);
        const originalNodes = this.flatTree(this.treeSelects, this.getLeafs);

        const leafs = this.flatTree(treeSelects, this.getLeafs);
        const nodes = this.flatTree(treeSelects, this.getTreeNodes);

        nodes.forEach(node => node.isVisible = false);

        leafs.forEach(leaf => {
            const originalNode = originalNodes.find(node => node.id == leaf.id);
            leaf.isSelected = originalNode.isSelected;

            if (this.searchMatch(leaf, searchedText)) {
                leaf.isVisible = true;
                this.expandParentNodes(leaf);
                this.setVisibleParentNodes(leaf);
            } else {
                leaf.isVisible = false;
            }
        });

        nodes.forEach(node => {
            if (this.searchMatch(node, searchedText)) {
                node.isVisible = true;
                this.expandParentNodes(node);
                this.setVisibleParentNodes(node);
                this.expandChildrenNodes(node);
                this.setVisibleChildrenNodes(node);
            }
        });

        this.treeSelects = treeSelects;
        return treeSelects;
    }

    selectLeafs(entities: any[], openWhenSelected: boolean) {
        const treeSelects = this.resetTree(this.treeSelects);
        if (!Array.isArray(entities) || !entities?.length) {
            this.treeSelects = treeSelects;
            return treeSelects;
        }

        const leafs = this.flatTree(treeSelects, this.getLeafs);

        entities.forEach((entity) => {
            const leaf = leafs.find((leaf: TreeSelect<T>) => leaf.id == this.getLeafId(entity));
            if (!leaf) return;

            leaf.isSelected = true;
            if (openWhenSelected) {
                this.expandParentNodes(leaf);
            }
        });

        this.treeSelects = treeSelects;
        return treeSelects;
    }

    private getLeafId(entity: any) {
        return typeof entity != 'object' ? entity : entity[this.options.idKey];
    }

    private flatTree(treeSelects: TreeSelect<T>[], queryTreeFunction: Function) {
        return flatMapDeep(treeSelects, queryTreeFunction);
    }

    private expandParentNodes(leaf: TreeSelect<T>) {
        if (leaf.children) leaf.isCollapsed = false;
        if (leaf.parent) {
            this.expandParentNodes(leaf.parent);
        }
    }

    private expandChildrenNodes(node: TreeSelect<T>) {
        if (node.children) {
            node.children.forEach((child: TreeSelect<T>) => {
                child.isCollapsed = false;
                this.expandChildrenNodes(child);
            })
        }
    }

    private setVisibleParentNodes(node: TreeSelect<T>) {
        if (node.parent) {
            node.parent.isVisible = true
            this.setVisibleParentNodes(node.parent);
        }
    }

    private setVisibleChildrenNodes(node: TreeSelect<T>) {
        if (node.children) {
            node.children.forEach((child: TreeSelect<T>) => {
                child.isVisible = true;
                this.expandChildrenNodes(child);
            })
        }
    }

    private searchMatch(node: TreeSelect<T>, searchedText: string) {
        return node.displayName.toUpperCase().startsWith(searchedText.toUpperCase());
    }

    private resetTree(treeSelects: TreeSelect<T>[]) {
        return treeSelects.map((treeSelect) => treeSelect.resetTree());
    }

    private getLeafs = (node) => {
        if (!node.children || !node.children.length) return node;
        return [flatMapDeep(node.children, this.getLeafs)];
    }

    private getTreeNodes = (node) => {
        if (node.children && node.children.length) return [node, flatMapDeep(node.children, this.getTreeNodes)];
        return [flatMapDeep(node.children, this.getTreeNodes)];
    }

    private getTreeNodesAndLeafs = (node) => {
        if (!node.children || !node.children.length) return node;
        return [node, flatMapDeep(node.children, this.getTreeNodesAndLeafs)];
    }
}
