import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TreeSelect } from "../../models/tree-select/tree-select.model";


@Component({
    selector: 'app-tree-select',
    styleUrls: ['tree-select.component.scss'],
    templateUrl: 'tree-select.component.html'
})
export class TreeSelectComponent<T> {

    @Input() treeSelects: TreeSelect<T>[]

    @Output() toggleCheckbox: EventEmitter<null>;

    constructor() {
        this.toggleCheckbox = new EventEmitter();
    }

    onToggleCheckbox(selected: boolean) {
        this.toggleCheckbox.emit();
    }
}