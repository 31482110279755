import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '@vedrai/vedrai-ui';
import { NgxEchartsVendorModule } from '@vendors/modules/ngx-echarts';
import { DragScrollVendorModule } from '@vendors/modules/drag-scroll';
import { NgBootstrapVendorModule } from '@vendors/modules/ng-bootstrap';
import { FontAwesomeVendorModule } from '@vendors/modules/fontawesome';
import { NgxTranslateVendorModule } from '@vendors/modules/ngx-translate';
import { FiltersSelectorComponent } from './widgets/filters-selector/filters-selector.component';
import { SeriesSelectorComponent } from './widgets/series-selector/series-selector.component';
import { VariableFiltersModalComponent } from './widgets/variable-filters-modal/variable-filters-modal.component';
import { ConfirmActionModalComponent } from './widgets/confirm-action-modal/confirm-action-modal.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        UiModule,

        NgxTranslateVendorModule,
        FontAwesomeVendorModule,
        NgBootstrapVendorModule,
        NgxEchartsVendorModule,
        ReactiveFormsModule,
        DragScrollVendorModule
    ],
    declarations: [
        VariableFiltersModalComponent,
        FiltersSelectorComponent,
        SeriesSelectorComponent,
        ConfirmActionModalComponent
    ],
    exports: [
        VariableFiltersModalComponent,
        FiltersSelectorComponent,
        SeriesSelectorComponent
    ]
})
export class SharedModule { }
