
import { deepClone } from "@vedrai/vedrai-core";
import { Filters } from "../../entities/variable/filters.model";
import { FiltersChart } from "./filters-chart.model";

// Tree chart
export class FiltersTreeChart extends FiltersChart {

    get parent() {
        return this.data;
    }

    constructor(chart, filters: Filters) {
        super(chart);
        this.updateChart(filters);
    }

    updateChart(filters: Filters) {

        const series = this.getSeriesById('tree');
        this.data = deepClone(filters);
        series.data = [this.data];
        series.label.formatter = this.filtersSelectionsFormatter;

        this.updateChartInstance();
    }

    updateHighlight(shortcutFilters: Filters[]) {
        this.highlight(shortcutFilters);
        this.updateChartInstance();
    }

    initSelections(selectedFilter: Filters, shortcutFilters: Filters[]) {
        this.openFirstLevel();
        this.selectFilter(selectedFilter);
        this.highlight(shortcutFilters);
    }

    selectFilter(filter: Filters) {

        if (this.selectedFilter) {
            const currentFilter = this.treeSearch(this.selectedFilter.id, this.parent);

            if (currentFilter) {
                currentFilter.collapsed = true;
            }
        }

        if (filter) {
            this.openOnlySelectedBranch(filter);
        }

        this.selectedFilter = filter;
        this.updateChartInstance();
    }

    private highlight(shortcutFilters: Filters[], children: Filters[] = this.firstChilden) {
        children.forEach((filter: Filters) => {
            const isShortcut = !!shortcutFilters.find((shortcutFilter) => shortcutFilter.id == filter.id);
            filter.isShortcut = isShortcut;
            if (filter.children?.length) {
                this.highlight(shortcutFilters, filter.children);
            }
        });
    }

    private openOnlySelectedBranch(selectedFilter: Filters) {
        const treePath = this.treeSearchPath(selectedFilter.id, this.parent);
        (function openOneBranch(treePath: Filters[], children: Filters[], level: number = 1) {
            if (!treePath[level]) return;
            children.forEach((child) => {
                if (treePath[level].id == child.id) {
                    child.collapsed = false;
                    openOneBranch(treePath, child.children, level + 1);
                } else {
                    child.collapsed = true;
                }
            })
        })(treePath, this.parent.children)
    }

    private openFirstLevel() {
        this.parent.collapsed = false;
    }

    private filtersSelectionsFormatter = ({ data }) => {
        const name = this.truncateWord(data.display_name);
        if (data.id == this.selectedFilter?.id) {
            return `{selected|${name}}`;
        }
        return data.isShortcut ? `{shortcut_bar|}{base|${name}}` : `{base|${name}}`;
    }

    private truncateWord(name: string) {
        if (name?.length > 30) {
            return name.substring(0, 27) + '...';
        }
        return name;
    }

}