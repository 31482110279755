<div class="modal-body">
    <span class="text-sm">{{confirmActionMessage | translate}}</span>
</div>
<div class="modal-footer flex-between-center">
    <button type="button" class="btn btn-linked btn-flat text-muted m-0" (click)="onClose()">
        {{'operations.cancel' | translate}}
    </button>
    <button type="submit" class="btn btn-primary m-0" (click)="onConfirm()">
        {{'operations.confirm' | translate}}
    </button>
</div>