import {
    Component, Input, ChangeDetectionStrategy,
    TemplateRef, Output, EventEmitter,
    DoCheck, ChangeDetectorRef, ViewChild, HostBinding, ElementRef
} from '@angular/core';
import { TableOrderBy, TableLabels } from '@vedrai/vedrai-ui';
import { fromEvent, Subscription } from 'rxjs';
import { TableRowGroupConfig } from '../../models/table-row-group/table-row-group-config.models';
import { ITableStickyColumn, TableStickyColumn } from '../../models/table-sticky/table-sticky-config.model';


@Component({
    selector: 'app-table-row-group',
    templateUrl: './table-row-group.component.html',
    styleUrls: ['./table-row-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableRowGroupComponent implements DoCheck {

    expandedRow: any = null;

    private resizeSubscription: Subscription;

    private _configs: TableRowGroupConfig;

    private _columns: ITableStickyColumn[];

    private _data: any;

    private _groupRowKeys: string[];

    private updateStickyOptions = false;

    @Input() configs: TableRowGroupConfig;

    @Input() compact: boolean;

    @Input() inLoading = false;

    // HEADER
    @Input() headerTemplate: TemplateRef<any>;

    @Input() rowGroupTemplate: TemplateRef<any>;

    @Input() rowGroupActionTemplate: TemplateRef<any>;

    // LOADER
    @Input() loaderTemplate: TemplateRef<any>;

    @Input() tableClass: string;

    @Input() theadClass: string;

    @Output() orderBy: EventEmitter<TableOrderBy>


    @Output() toggleSelection: EventEmitter<any[]>;

    @Input() index: number = 1;

    @ViewChild('rowGroupTable', { static: true }) rowGroupTable: ElementRef;

    @HostBinding("style") scrollbarMargins = {
        '--data-scrollbar-margin-left': 0,
        '--data-scrollbar-margin-right': 0,
        '--data-scrollbar-margin-top': 0
    };

    get isConfigurated(): boolean {
        return !!this.configs;
    }

    get isCompact(): boolean {
        return typeof this.compact == 'boolean' ? this.compact : this.configs.isCompact;
    }

    get labelPrefix(): string {
        return this.configs.labelPrefix ? this.configs.labelPrefix + '.' : '';
    }

    get labels(): TableLabels {
        return this.configs.labels || null;
    }

    get useSortIndex(): boolean {
        return this.configs.useSortIndex;
    }

    get rowGroups(): any {
        return this._data;
    }

    get groupRowKeys(): string[] {
        return this._groupRowKeys
    }

    get collapseRowGroups(): boolean {
        return this.configs.collapseRowGroups;
    }

    get isRowGroupCollapsed(): { [key: string]: boolean } {
        return this.configs.rowGroupKeysCollapsed;
    }

    get columns(): ITableStickyColumn[] {
        return this._columns;
    }

    set columns(columns: ITableStickyColumn[]) {
        this._columns = columns;
    }

    constructor(private ref: ChangeDetectorRef) {
        this.orderBy = new EventEmitter();
        this.toggleSelection = new EventEmitter();

        this.resizeSubscription = fromEvent(window, 'resize')
            .subscribe(evt => this.setStickyOption());
    }

    ngDoCheck() {
        if (this.configs != this._configs) {
            this._configs = this.configs;
            this.storeColumns();
        }

        if (this.configs.data != this._data) {
            this._data = this.configs.data;
            this._groupRowKeys = this.configs.groupRowKeys;
            this.updateStickyOptions = true;
            this.ref.detectChanges();
        }
    }

    ngAfterViewChecked() {
        if (!this.shouldSetStickyOptions()) return;

        this.setStickyOption();
        this.updateStickyOptions = false;
        this.ref.detectChanges();
    }

    ngOnDestroy(): void {
        this.resizeSubscription.unsubscribe();
    }

    toggleRowGroup(key: string) {
        this.isRowGroupCollapsed[key] = !this.isRowGroupCollapsed[key]
    }

    /**
     * use this funtion to store current columns configs in order
     * to apply a correct refresh on current configs and not on initialization configs
     */
    public storeColumns() {
        if (this.configs) {
            this.columns = this.configs.columns.map((column: TableStickyColumn) => {
                return new TableStickyColumn(column);
            });
        }
    }

    /**
     * refresh component by apply again you initial columns configurations 
     */
    public refresh() {
        this.storeColumns();
    }

    private shouldSetStickyOptions() {
        const tableColumns = this.getTableColumns();
        return this.updateStickyOptions && Object.keys(this.rowGroups).length && tableColumns.length == this.columns.length
    }


    private setStickyOption() {
        const scrollbarMargins = { left: 0, right: 0, top: 0 };

        const tableColumns = this.getTableColumns();

        scrollbarMargins.top = tableColumns[0].clientHeight;

        const accumulator = {
            left: 0,
            right: this.getTableWidth(tableColumns)
        }

        for (let i = 0; i < tableColumns.length; i++) {
            const th: HTMLElement = <HTMLElement>tableColumns[i];
            const sticky = this.getStickyAttribute(th);
            const width = th.clientWidth;

            accumulator.right -= width;
            if (sticky) {
                const stickyValue = sticky.nodeValue;
                this.columns[i][stickyValue] = accumulator[stickyValue];
                scrollbarMargins[stickyValue] += width;
            }
            accumulator.left += width;
        }

        const { left, right, top } = scrollbarMargins;
        this.setScrollbarMargin(left, right, top);
    }

    private getTableWidth(tableColumns: Element[]) {
        return tableColumns.reduce((res, column) => res + column.clientWidth, 0)
    }

    private getTableColumns() {
        const tableElement = this.rowGroupTable.nativeElement;
        return Array.from(this.getTableHeader(tableElement).children);
    }

    private getTableHeader(table: HTMLTableElement) {
        return table.rows[0];
    }

    private getStickyAttribute(el: HTMLElement) {
        return el.getAttributeNode('sticky');
    }

    private setScrollbarMargin(left: number, right: number, top: number) {
        this.scrollbarMargins = {
            '--data-scrollbar-margin-left': left,
            '--data-scrollbar-margin-right': right,
            '--data-scrollbar-margin-top': top
        };
    }

}
