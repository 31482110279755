import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { VedraiSkltnModule } from "@vedrai/vedrai-ui";
import { SkltnChartComponent } from "./skltn-chart/skltn-chart.component";
import { SkltnCheckboxesComponent } from "./skltn-checkboxes/skltn-checkboxes.component";
import { SkltnIndexAnalysisTableComponent } from "./skltn-index-analysis-table/skltn-index-analysis-table.component";
import { SkltnTableComponent } from "./skltn-table/skltn-table.component";
import { SkltnTabsComponent } from './skltn-tabs/skltn-tabs.component';
import { SkltnTogglesComponent } from "./skltn-toggles/skltn-toggles.component";

@NgModule({
    imports: [
        CommonModule,
        VedraiSkltnModule
    ],
    declarations: [
        SkltnChartComponent,
        SkltnCheckboxesComponent,
        SkltnTogglesComponent,
        SkltnTableComponent,
        SkltnIndexAnalysisTableComponent,
        SkltnTabsComponent
    ],
    exports: [
        SkltnChartComponent,
        SkltnCheckboxesComponent,
        SkltnTableComponent,
        SkltnIndexAnalysisTableComponent,
        SkltnTabsComponent,
        VedraiSkltnModule
    ]
})
export class SkltnsVendorModule { }