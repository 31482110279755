import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({ name: 'pluralTranslate' })
export class PluralTranslatePipe implements PipeTransform {

    constructor(private translateService: TranslateService) {
    }

    transform(key: string, number: number): string {

        const translateKey = `${key}.${number == 0 ? 'none' : number == 1 ? 'singular' : 'plural'}`;

        return this.translateService.instant(translateKey, { number });
    }
}