import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Notification } from '../models/notification/notification.model';
import { NotificationsFactory } from '../models/notification-factory.model';
import { NotificationInstanceType } from '../models/notification-types.model';
import { ModalNotification } from '../models/notification/modal-notification.model';
import { ModalOptions, ModalsService } from '@vendors/modules/ng-bootstrap';
import { NotificationModalComponent } from '../widgets/notification-modal/notification-modal.component';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    notifications$: BehaviorSubject<NotificationInstanceType[]>;
    notificationsNumber$: BehaviorSubject<number>;

    messages: any;

    get notificationNumber() {
        return this.notificationsNumber$.getValue();
    }

    get notifications() {
        return this.notifications$.getValue();
    }

    set notifications(notifications: Notification[]) {
        this.notifications$.next(notifications);

        this.notificationsNumber$.next(notifications.length);
    }

    constructor(private translateService: TranslateService,
        private toastr: ToastrService,
        private modalsService: ModalsService) {
        this.notifications$ = new BehaviorSubject([]);
        this.notificationsNumber$ = new BehaviorSubject(0);
    }

    setNotification(message: string) {
        const notification = NotificationsFactory.createNotification(message);

        this.notifications = [notification, ...this.notifications];

        if (notification.type == 'MN') {
            this.showModalNotification(notification);
        }
        this.showToast(notification);
    }

    private translate(label: string, model: any) {
        return label ? this.translateService.instant(label, model) : '';
    }

    private showToast(notification: NotificationInstanceType) {
        const { notify, description, title } = notification;
        this.toastr[notify](
            this.translate(description, notification),
            this.translate(title, notification)
        );
    }

    private showModalNotification(notification: ModalNotification) {
        console.log(notification)
        this.modalsService.openModal(new ModalOptions({
            component: NotificationModalComponent,
            isEmbedded: false,
            data: notification,
            size: 'md'
        }))
    }

    clearNotification() {
        this.notifications = [];
    }

}