import { Chart } from "@vendors/modules/ngx-echarts";
import { deepClone } from "@vedrai/vedrai-core";
import { getFormattedDate, numberNormalization } from "@shared";
import { VariableTimeSeries } from "../time-series/variable-time-series.model";
import { VariableSeries } from "../series/variable-series.model";
import { PeriodType } from '../../dates/period-types.model';
import { SeriesType } from "../echarts-types.model";


export interface ITimeSeriesHighlight {
  period: PeriodType;
  frequency: PeriodType;
  startDate: string;
  endDate: string;
}

export class VariableChart extends Chart {

  timeSeries: VariableTimeSeries;
  datesFormat = 'MMM y';
  highlightColor = 'rgba(94, 114, 228, 0.1)';
  realForecastValue: number;

  readonly zoomPeriods = {
    year: 4,
    quarter: 16,
    month: 48
  }

  seriesColors = {
    real: "#626c91",
    forecast: "#5e72e4",
    trend: '#ff6361',
    seasonality: '#fcbf49'
  }

  get variable() {
    return this.timeSeries.entity;
  }

  get unit() {
    return this.variable.unit_meas || '';
  }

  get dates() {
    return this.xAxis.data;
  }

  constructor(chart, timeSeries: VariableTimeSeries, seriesType: SeriesType = 'all', highlight?: ITimeSeriesHighlight) {
    super(chart);
    this.updateChart(timeSeries, seriesType, highlight);

    this.tooltip.formatter = this.tooltipFormatter;
    this.yAxisLabel.formatter = this.yAxisFormatter;
  }


  updateChart(timeSeries: VariableTimeSeries, seriesType: SeriesType = 'all', highlight?: ITimeSeriesHighlight) {
    this.chart.series = [];
    this.legend.data = [];

    this.timeSeries = timeSeries;
    const { dates, series } = timeSeries;

    const parsedDates = dates.map((date) => getFormattedDate(date, this.datesFormat));

    const { dates: newDates, series: newSeries, highlightIndexes } = this.fetchSeriesForType(series, parsedDates, seriesType, highlight);
    this.xAxis.data = newDates;

    newSeries.forEach((series) => this.addSeries(series, series.values, seriesType == series.id, highlightIndexes));

    this.updateChartInstance();
  }

  fetchSeriesForType(series: VariableSeries[], dates: string[], type: SeriesType, highlight?: ITimeSeriesHighlight) {

    series = deepClone(series);
    if (type == 'trend' || type == 'seasonality') {
      return { series, dates };
    }

    // Find series
    let real, forecast;
    series.forEach((series) => {
      if (series.seriesType == 'real') real = series;
      if (series.seriesType == 'forecast') forecast = series;
    });

    // Manage default behaviours
    if (!real || (type != 'real' && !forecast)) {
      return { series, dates };
    }

    const lastRealValorizedIndex = (real.values.length - 1) - [...real.values].reverse().findIndex(val => val != null);
    if (forecast && real) {
      forecast.values[lastRealValorizedIndex] = real.values[lastRealValorizedIndex];
    }

    switch (type) {
      case 'real':
        real.values = real.values.slice(0, lastRealValorizedIndex + 1);
        dates = dates.slice(0, lastRealValorizedIndex + 1);
        series = [real];
        break;
      case 'forecast':
        const firstForecastValorizedIndex = forecast.values.findIndex(val => val != null);
        const initialPointRealIndex = firstForecastValorizedIndex - 4

        dates = dates.slice(initialPointRealIndex, forecast.values.length);
        real.values = real.values.slice(initialPointRealIndex, firstForecastValorizedIndex + 1);
        forecast.values = forecast.values.slice(initialPointRealIndex, forecast.values.length);
        break;
    }

    return { dates, series, highlightIndexes: this.setHightlight(dates, highlight, type) };
  }

  addSeries(series: VariableSeries, values: number[], selected: boolean = false, highlightIndexes?: any) {

    this.legend.data.push(series.label);
    this.legend.selected[series.label] = selected || series.selected;

    const isForecast = series.seriesType == 'forecast';

    const newSeries: any = {
      id: series.id,
      name: series.label,
      type: "line",
      data: values,
      color: this.seriesColors[series.seriesType],
      lineStyle: {
        type: isForecast ? 'dashed' : 'solid',
      },
      smooth: false,
      showSymbol: false,
      emphasis: {
        lineStyle: {
          width: 2
        }
      }
    };


    if (highlightIndexes) {
      newSeries.markLine = {
        silent: true,
        symbol: ['none'],
        label: { show: false },
        lineStyle: {
          color: this.highlightColor,
          width: 2
        },
        data: [{ xAxis: highlightIndexes.markerStart }, { xAxis: highlightIndexes.markerEnd }]
      };

      newSeries.markArea = {
        itemStyle: {
          color: this.highlightColor
        },
        data: [
          [
            {
              xAxis: highlightIndexes.startDate
            },
            {
              xAxis: highlightIndexes.endDate
            }
          ]
        ]
      }
    }

    this.series.push(newSeries);
  }

  showSeries(id, show: boolean) {
    this.legend.selected[id] = show;
    this.instance.setOption(this.chart);
  }

  changeType(type: 'line' | 'bar') {
    this.series.forEach(series => {
      if (series.type == type) return;
      series.type = type;

      if (series.id == 'forecast') {
        const firstValorizedIndex = series.data.findIndex((value) => value != null)
        if (type == 'bar') {
          this.realForecastValue = series.data[firstValorizedIndex];
          series.data[firstValorizedIndex] = null;
        } else {
          series.data[firstValorizedIndex - 1] = this.realForecastValue;
        }
      }

    });
    this.instance.setOption(this.chart);
  }

  setHightlight(dates: string[], highlight: ITimeSeriesHighlight, type: SeriesType) {

    if (!highlight) return null;

    const highlightIndexes = this.getHighlightIndexes(dates, highlight, type);
    const { zoomStart: startValue, zoomEnd: endValue } = highlightIndexes;

    this.dataZoom = this.dataZoom.map((zoom) => ({
      ...zoom,
      startValue,
      endValue
    }));

    return highlightIndexes;
  }

  private getHighlightIndexes(dates: string[], { startDate, endDate, frequency, period }: ITimeSeriesHighlight, type: SeriesType) {

    const shortStartDate = getFormattedDate(startDate, this.datesFormat);
    const shortEndDate = getFormattedDate(endDate, this.datesFormat);

    let firstIndex, lastIndex;
    const previousPeriod = frequency == 'month' && period == 'month' ? 1 : 0;

    dates.forEach((date, index) => {
      if (date == shortStartDate) firstIndex = index;
      if (date == shortEndDate) lastIndex = index;
    });


    return {
      startDate: dates[firstIndex - previousPeriod],
      endDate: shortEndDate,
      markerStart: firstIndex - previousPeriod,
      markerEnd: lastIndex,
      zoomStart: type == 'real' ?
        (firstIndex >= 8 ? firstIndex - 6 : firstIndex) :
        (firstIndex >= 5 ? firstIndex - 5 : firstIndex),
      zoomEnd: lastIndex + 4
    }
  }

  setZoom(periodType: PeriodType) {
    this.dataZoom = this.dataZoom.map((zoom) => ({
      ...zoom,
      startValue: ((this.dates.length) - this.zoomPeriods[periodType]) + 1,
      endValue: this.dates.length
    }));
  }

  private yAxisFormatter = (value) => `${numberNormalization(value)} ${this.unit}`;

  private tooltipFormatter = (params: any[]) => {
    return params.reverse().reduce((res, item, index) => {
      let value = typeof item.value == 'number' ?
        item.value : null;

      return res + (value != null ?
        `<div class="flex-between-center ${index ? 'mt-2' : 'mt-0'}">
        <span class="mr-4">${item.marker} ${item.seriesName}</span>
        <b>${this.yAxisFormatter(value)}</b>
        </div>` : '');
    }, `<div class="mb-2">${params[0].axisValue}</div>`);
  }

}