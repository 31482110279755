export type ChartType = 'line' | 'bar';

export type SeriesType = 'all' | 'forecast' | 'real' | 'trend' | 'seasonality';

export type SeriesGroupsType = 'real' | 'trend' | 'seasonality';

export type SideSeriesType = `${string}-${SeriesType}-${SidesType}`;

export type SeriesIdType = SeriesType | SideSeriesType;

export type SidesType = 'left' | 'right';

export type IntelligencesType = 'forecast' | 'real';
