import { NgModule } from '@angular/core';

import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgxToastrVendorModule } from '@vendors/modules/ngx-toastr';
import { AuthInterceptor } from './services/interceptors/auth.interceptor';
import { ErrorInterceptor } from './services/interceptors/error.interceptor';
import { AuthGuard } from './services/authorizations/auth.guard';
import { AuthService } from './services/authorizations/auth.service';
import { ConfigsService } from './services/authorizations/configs.service';
import { NgxGoogleAnalyticsVendorModule } from '@vendors/modules/ngx-google-analytics';
import { LanguageInterceptor } from './services/interceptors/language.interceptor';

@NgModule({
    imports: [
        NgxToastrVendorModule,
        NgxGoogleAnalyticsVendorModule
    ],
    providers: [
        AuthGuard,
        AuthService,
        ConfigsService,
        AuthInterceptor,
        ErrorInterceptor,
        LanguageInterceptor,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LanguageInterceptor,
            multi: true
        }
    ]
})
export class CoreModule { }
