import { ExcelTemplate } from './../models/excel/excel-template.mode';
import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import { FileSaverService } from 'ngx-filesaver';

@Injectable()
export class ExportExcelService {

    constructor(private fileSaverService: FileSaverService) { }

    exportExcel(template: ExcelTemplate<any>, fileName: string) {
        this.downloadExcel(template.workBook, fileName);
    }

    private async downloadExcel(workbook: Workbook, fileName: string) {
        const blobFile = await this.generateExcel(workbook);
        this.saveExcel(blobFile, fileName);
    }

    private async generateExcel(workbook: Workbook) {
        const data = await workbook.xlsx.writeBuffer();
        return new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
    }

    private saveExcel(blob: Blob, fileName: string) {
        // TODO valuate manual download
        this.fileSaverService.save(blob, fileName + '.xlsx');
    }
}


// downloadCsv = () => {
//     console.log(csvContent);

//     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
//     const link = document.createElement("a");

//     link.setAttribute("href", window.URL.createObjectURL(blob));
//     link.setAttribute("download", "chart.csv");
//     link.setAttribute("hidden", "true");

//     link.click();
// }