import { TimeSeries, ITimeSeries, IParsedTimeSeries } from "@vendors/modules/ngx-echarts";
import { Variable } from "../../entities/variable/variable.model";
import { ScenarioSeries } from "../series/scenario-series.model";

export class ScenarioTimeSeries extends TimeSeries<Variable>  {

    series: ScenarioSeries[];

    constructor(timeSeries?: ITimeSeries) {
        super(new Variable(timeSeries.entity, 'kpis'), timeSeries);
    }

    initSeries(parsedTimeSeries: IParsedTimeSeries) {
        const { paramsKeys, timeSeries, variableNames } = parsedTimeSeries;
        return paramsKeys.map((param: string) => new ScenarioSeries({
            id: param,
            label: variableNames[param],
            values: timeSeries[param]
        }));
    }

}