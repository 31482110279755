import { NgForOf } from '@angular/common';
import { Directive, Host } from '@angular/core';

interface Item {
    id: number;
}

@Directive({
    selector: '[ngForTrackById]',
})
export class TrackByIdDirective<T extends Item> {
    constructor(@Host() private readonly ngFor: NgForOf<T>) {
        this.ngFor.ngForTrackBy = (index: number, item: T) => item.id;
    }
}