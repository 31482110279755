import { getTime } from '@shared';
import { NotificationType } from '../notification-types.model';
import notificationConfigs from '@configs/notifications/notifications.json';

export interface INotification {
    type: NotificationType;
    value: any;
}

export class Notification {

    created: string;
    type: NotificationType;
    value: any;
    consumed: boolean = false;

    protected configs: any;

    constructor(notification: INotification) {
        this.created = getTime();
        this.type = notification.type;
        this.value = notification.value;
        this.configs = notificationConfigs?.notifications;
    }

}