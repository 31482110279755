import { Injectable } from '@angular/core';
import { PeriodType } from '@models';
import { getPeriod } from '@shared';
import { RequestService } from '@vedrai/vedrai-core';
import { Configs, IConfigs } from '../../models/configs.model';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ConfigsService {

  readonly defaultPeriod: PeriodType = 'year';
  configs$: BehaviorSubject<Configs>;
  periods$: BehaviorSubject<PeriodType[]>;

  get configs(): Configs {
    return this.configs$.getValue();
  }

  set configs(configs: Configs) {
    this.configs$.next(configs);
  }

  // First date available for client datases
  get startDate() {
    return this.configs.start_date;
  }

  // Last date available for client datases
  get endDate() {
    return this.configs.end_date;
  }

  get forecastStartDate() {
    return this.configs.start_date_forecast;
  }

  get forecastEndDate() {
    return this.configs.end_date_forecast;
  }

  get frequency(): PeriodType {
    return getPeriod(this.configs.frequency);
  }

  get periods() {
    return this.periods$.getValue();
  }

  set periods(periods: PeriodType[]) {
    this.periods$.next(periods);
  }

  constructor(private requestService: RequestService) {
    this.periods$ = new BehaviorSubject([]);
    this.configs$ = new BehaviorSubject(new Configs());
  }

  async initConfigs() {
    let configs = new Configs();

    try {
      configs = await this.getConfigurations();
    } catch (error) {
      console.error('Configuration not available: ', error, ' Default in use');
    }

    this.configs = configs;
    this.periods = this.getAvailablePeriods(this.frequency);
  }

  getConfigurations() {
    return this.requestService.execRequest('get', 'configurations')
      .pipe(map((configs: IConfigs) => new Configs(configs))).toPromise();
  }

  private getAvailablePeriods(frequency: PeriodType) {
    const periods = [];
    switch (frequency) {
      case 'month':
        periods.push('month');
      case 'quarter':
        periods.push('quarter');
      case 'year':
        periods.push('year');
    }
    return periods.reverse();
  }

}
