import {
    faHourglassHalf,
    faExclamation,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faArrowTrendDown,
    faArrowTrendUp,
    faBell,
    faCaretDown,
    faCaretUp,
    faCardsBlank,
    faCardHeart,
    faChartArea,
    faChartBar,
    faChartColumn,
    faChartLine,
    faChartLineUp,
    faChartPie,
    faChartWaterfall,
    faCopy,
    faDownload,
    faEllipsisV,
    faEye,
    faFileArrowDown,
    faFilePlus,
    faFilter,
    faFilters,
    faFloppyDisk,
    faGripHorizontal,
    faHome,
    faLightbulb,
    faLock,
    faLongArrowAltRight,
    faMagnifyingGlass,
    faPen,
    faPenToSquare,
    faPencil,
    faRedoAlt,
    faRightFromBracket,
    faSitemap,
    faSquareList,
    faThumbsDown,
    faThumbtack,
    faThumbsUp,
    faTimes,
    faTrashAlt,
    faUser,


    faEuroSign,
    faPercentage,
    faPlus,
    faSync,
    faTrash,
    faQuestion,
    faCircleNotch,
    faFileDownload,
    faCircleInfo,
    faCircleExclamation,
    faXmarkLarge,
    faBars,
    faTrashCan,
    faRotate,

    faCalendarDay,
    faCalendarWeek,
    faCalendarAlt,
    faCalendarCheck,
    faCalendarTimes,
    faExclamationTriangle,

    IconDefinition
} from '@fortawesome/pro-solid-svg-icons';

export const fas: IconDefinition[] = [
    faHourglassHalf,
    faExclamation,
    faArrowTrendDown,
    faArrowTrendUp,
    faHome,
    faBell,
    faCardsBlank,
    faCaretDown,
    faCaretUp,
    faCardHeart,
    faChartArea,
    faChartBar,
    faChartColumn,
    faChartLine,
    faChartLineUp,
    faChartPie,
    faChartWaterfall,
    faEye,
    faFileArrowDown,
    faFilePlus,
    faFloppyDisk,
    faUser,
    faRightFromBracket,
    faPen,
    faPenToSquare,
    faPencil,
    faSquareList,
    faMagnifyingGlass,

    faSitemap,
    faGripHorizontal,
    faTimes,
    faThumbtack,
    faTrashAlt,
    faRedoAlt,
    faLightbulb,
    faLock,
    faLongArrowAltRight,
    faEllipsisV,
    faThumbsUp,
    faThumbsDown,
    faArrowUp,
    faArrowDown,
    faArrowLeft,

    faArrowRight,
    faCopy,
    faDownload,
    faFilter,
    faFilters,
    faEuroSign,
    faPercentage,
    faPlus,
    faSync,
    faTrash,
    faQuestion,
    faCircleNotch,
    faFileDownload,
    faCircleInfo,
    faCircleExclamation,
    faXmarkLarge,
    faBars,
    faTrashCan,
    faRotate,

    faCalendarDay,
    faCalendarWeek,
    faCalendarAlt,
    faCalendarCheck,
    faCalendarTimes,
    faExclamationTriangle
];