<div class="card shadow">
    <div class="card-body m-5 p-0">
        <div class="row flexw-around-center">

            <div class="col placeholder-image" [ngClass]="[imageSize, image]"></div>
            <div class="col-lg-8 col-sm-12 col-md-12 mt-3">
                <div class="row">
                    <div *ngIf="icon" class="col-auto">
                        <div class="icon icon-shape bg-gradient-primary text-white rounded-circle">
                            <fa-icon class="mb--1" [icon]="icon"></fa-icon>
                        </div>
                    </div>
                    <div class="col" [class.pl-1]="icon">
                        <h5 class="card-title text-xs text-uppercase text-muted mb-0">
                            {{imageTitle | translate}}
                        </h5>
                        <h3 class="font-weight-bold mb-0">
                            {{subtitle | translate}}
                        </h3>
                    </div>

                </div>
                <p class="my-3 text-sm">
                    {{description | translate}}
                </p>
                <ng-content select="[cta]"></ng-content>
            </div>

        </div>
    </div>