import { ITableConfigs, TableLabels } from "@vedrai/vedrai-ui";
import { TableStickyColumn } from "../table-sticky/table-sticky-config.model";

export type DirectionTypes = 'ASC' | 'DESC' | null;

export interface ITableRowGroupConfig extends Omit<ITableConfigs, 'data'> {
    sticky?: boolean;
    data?: any;
    columns: any[]
    groupRowKeys?: string[];
    rowGroupKeysCollapsed?: { [key: string]: boolean };
    collapseRowGroups?: boolean;
}

export interface ITableRowGroupConfigWrapper {
    configs: ITableRowGroupConfig;
    presentationConfigs?: any;
    groupBy: string | Function;
    orderBy?: Function;
    groupOrderDirection?: DirectionTypes;
}

export class TableRowGroupConfig implements ITableRowGroupConfig {
    useSortIndex: boolean;
    columns: TableStickyColumn[];
    isCompact: boolean;
    labelPrefix?: string;
    labels?: TableLabels;
    data?: any;
    groupRowKeys?: string[];
    rowGroupKeysCollapsed?: { [key: string]: boolean };
    collapseRowGroups: boolean;

    constructor(configs: ITableRowGroupConfig) {
        this.useSortIndex = configs.useSortIndex === true;
        this.columns = (configs.columns || []).map((column) => new TableStickyColumn(column));
        this.data = configs.data || [];
        this.labelPrefix = configs.labelPrefix || '';
        this.labels = new TableLabels(configs.labels);
        this.isCompact = configs.isCompact || false;
        this.groupRowKeys = configs.groupRowKeys || undefined;
        this.rowGroupKeysCollapsed = configs.rowGroupKeysCollapsed || undefined;
        this.collapseRowGroups = configs.collapseRowGroups || false;
    }
}
