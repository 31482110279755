import { PeriodSymbolType } from "@models";
import { today } from "@shared";

export interface IConfigs {
    forecast_ready?: boolean;
    frequency?: PeriodSymbolType;
    start_date?: string;
    end_date?: string;
    start_date_forecast?: string;
    end_date_forecast?: string;
}

export class Configs {
    forecast_ready: boolean;
    frequency: PeriodSymbolType;
    start_date: string;
    end_date: string;
    start_date_forecast: string;
    end_date_forecast: string;

    constructor(configs?: IConfigs) {
        this.forecast_ready = configs?.forecast_ready || false;
        this.frequency = configs?.frequency || 'M';

        // TODO find a better default
        this.start_date = configs?.start_date || today();
        this.end_date = configs?.end_date || today();
        this.start_date_forecast = configs?.start_date_forecast || today();
        this.end_date_forecast = configs?.end_date_forecast || today();
    }
}