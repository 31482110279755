import { TreeSelectOptions } from "./tree-select-options.model";

export class TreeSelect<T> {

    parent: TreeSelect<T>;
    children: TreeSelect<T>[];

    isCollapsed: boolean = true;
    isSelected: boolean = false;
    isVisible: boolean = true;

    private options: TreeSelectOptions;
    private entity: T;

    get id() {
        return this.entity[this.options.idKey];
    }

    get displayName(): string {
        return this.entity[this.options.displayNameKey];
    }

    constructor(entity: T, options: TreeSelectOptions, parent?: TreeSelect<T>) {
        this.entity = entity;
        this.options = options;
        this.parent = parent;
        this.children = this.setChildren(entity);
    }

    private setChildren(entity: T) {
        const children = entity[this.options.childrenKey];
        if (!children) return null;

        return children.map((child: T) => new TreeSelect(child, this.options, this));
    }

    resetTree() {
        return new TreeSelect(this.entity, this.options);
    }

}