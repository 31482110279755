import { PeriodType } from "../../dates/period-types.model";
import { SidesType } from "../../echarts/echarts-types.model";
import { IVariableExam, VariableExam } from "../variable/variable-exam.model";


export interface ISide extends IVariableExam {
    color: string;
    sideType: SidesType;
}

export class Side extends VariableExam {
    color: string;
    sideType: SidesType;

    constructor(sideType: SidesType, color: string, period: PeriodType) {
        super(null, period);
        this.sideType = sideType;
        this.color = color;
    }
}