<skltn-root  bgFill="#bbb">
  <div [ngStyle]="{'height': h + 'px', 'width': w + 'px'}" skltn-bone
  type="path" [pathWidth]="pathW"
    [pathHeight]="pathH">
    <ng-template #boneTemp>
      <svg viewBox="0 0 5954 548" preserveAspectRatio="xMidYMin slice" [attr.width]="svgW" [attr.height]="svgH"
        xmlns="http://www.w3.org/2000/svg">
        <use [attr.href]="'#' + chartName"></use>
      </svg>
    </ng-template>
  </div>
</skltn-root>