import { ITableColumn, TableColumn, TableConfigs, ITableConfigs } from "@vedrai/vedrai-ui";

export interface ITableCollapsableColumn extends ITableColumn {
    sticky?: 'left' | 'right';
}

export class TableCollapsableColumn extends TableColumn {
    sticky: 'left' | 'right';

    constructor(tableColumn: ITableCollapsableColumn, presentationConfigColumn?: any) {
        super(tableColumn);
        Object.assign(this, tableColumn);
        if (presentationConfigColumn) {
            Object.assign(this, presentationConfigColumn);
        }
    }
}

export interface ITableCollapsableConfig extends ITableConfigs {
    columns: ITableCollapsableColumn[];
    isCollapsedKey?: string;
    childrenKey?: string;
    isSelectable?: boolean;
}

export class TableCollapsableConfig extends TableConfigs {

    columns: TableCollapsableColumn[];
    isSelectable: boolean;
    isCollapsedKey: string;
    childrenKey: string;

    data: any[];

    constructor(configs: ITableCollapsableConfig) {
        super(configs);

        this.columns = (configs.columns || []).map((column) => new TableCollapsableColumn(column));
        this.isSelectable = configs.isSelectable === true;
        this.isCollapsedKey = configs.isCollapsedKey || 'isCollapsed';
        this.childrenKey = configs.childrenKey || 'children';
    }
}

export interface ITableCollapsableConfigWrapper {
    configs: ITableCollapsableConfig;
    presentationConfigs?: any;
    rowClass?: Function | string;
}
