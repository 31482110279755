<div class="tree-select-root">
    <div *ngFor="let node of treeSelects" class="tree-select-group">
        <ng-container [ngTemplateOutlet]="node.children ? treeSelectGroupTemplate: treeSelectLeafTemplate"
            [ngTemplateOutletContext]="{$implicit:node}">
        </ng-container>
    </div>
</div>

<ng-template #recursiveTemplate let-nodes>
    <div *ngFor="let node of nodes" [ngClass]="node.children ? 'tree-select-group':null">
        <ng-container
            *ngTemplateOutlet="node.children ? treeSelectGroupTemplate: treeSelectLeafTemplate; context:{$implicit:node}">
        </ng-container>
    </div>
</ng-template>

<ng-template #treeSelectGroupTemplate let-node>
    <div *ngIf="node.isVisible" class="tree-select-title" (click)="collapse.toggle()"
        [attr.aria-expanded]="!node.isCollapsed" [attr.aria-controls]="node.id">
        <div class="flex-start-center">
            <button *ngIf="node.children" class="btn btn-link mr-2" [class.collapsed]="node.isCollapsed"></button>
            <b>{{node.displayName}}</b>
        </div>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="node.isCollapsed" class="collapse body-padding">
        <ng-container *ngTemplateOutlet="recursiveTemplate; context: {$implicit: node.children, parent: node}">
        </ng-container>
    </div>
</ng-template>

<ng-template #treeSelectLeafTemplate let-node>
    <div *ngIf="node.isVisible" class="tree-select-leaf">
        <div class="custom-control custom-checkbox pr-1">
            <input class="custom-control-input" [id]="node.id" type="checkbox" [(ngModel)]="node.isSelected"
                (ngModelChange)="onToggleCheckbox($event)">
            <label class="custom-control-label font-weight-300" [for]="node.id">
                {{node.displayName}}
            </label>
        </div>
    </div>
</ng-template>