import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CoreModule, FeatureFlagDirective } from "@vedrai/vedrai-core";
import { UiModule, VedraiSkltnModule } from "@vedrai/vedrai-ui";
import { FontAwesomeVendorModule } from "../fontawesome/fontawesome.vendor.module";
import { NgxTranslateVendorModule } from "../ngx-translate";
import { TablesService } from "./services/tables.service";
import { UtilsService } from "./services/utils.service";
import { PipesModule } from "./utilities/pipes/pipes.module";
import { ImagePlaceholderComponent } from "./widgets/image-placeholder/image-placeholder.component";
import { SearchInputComponent } from "./widgets/search-input/search-input.component";
import { SkltnsVendorModule } from "./widgets/skeletons/skltns-vendor.module";
import { TableRowGroupComponent } from "./widgets/table-row-group/table-row-group.component";
import { NgBootstrapVendorModule } from "../ng-bootstrap";
import { TreeSelectComponent } from "./widgets/tree-select/tree-select.component";
import { TableCollapsableComponent } from "./widgets/table-collapsable/table-collapsable.component";
import { UrlParamsService } from './services/url-params.service';
import { ChartLegendsComponent } from './widgets/chart-legends/chart-legends.component';
import { DirectivesModule } from './utilities/directives/directives.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,

        NgxTranslateVendorModule,
        FontAwesomeVendorModule,
        NgBootstrapVendorModule,

        CoreModule,
        UiModule,
        VedraiSkltnModule,
        SkltnsVendorModule,
        PipesModule,
        DirectivesModule,
        FeatureFlagDirective
    ],
    declarations: [
        ImagePlaceholderComponent,
        SearchInputComponent,
        TableRowGroupComponent,
        TreeSelectComponent,
        TableCollapsableComponent,
        ChartLegendsComponent,
    ],
    exports: [
        CoreModule,
        UiModule,
        VedraiSkltnModule,
        SkltnsVendorModule,
        PipesModule,
        DirectivesModule,

        ImagePlaceholderComponent,
        SearchInputComponent,
        TableRowGroupComponent,
        TableCollapsableComponent,
        TreeSelectComponent,
        ChartLegendsComponent,
        FeatureFlagDirective
    ]
})
export class VedraiKitVendorModule {
    static forRoot(): ModuleWithProviders<VedraiKitVendorModule> {
        return {
            ngModule: VedraiKitVendorModule,
            providers: [
                TablesService,
                UtilsService,
                UrlParamsService
            ]
        }
    }
}