import { assignColorBySentiment } from '@shared';
import { INotification } from './notification.model';
import { Notification } from './notification.model';

export class ProgressNotification extends Notification {

    progress: number = 0;
    color: string;

    get id(): string {
        return this.value?.id; 
    }

    get entityType(): string{
        return this.value?.entityType;
    }

    constructor(notification: INotification) {
        super(notification);

        if (!notification?.value) return;
        const { progress } = notification.value;

        this.progress = progress;
        this.color = assignColorBySentiment(this.progress);
    }
}