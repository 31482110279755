export interface ITreeSelectOptions {
    idKey?: string;
    displayNameKey?: string;
    childrenKey?: string;
}

export class TreeSelectOptions {
    idKey: string;
    displayNameKey: string;
    childrenKey: string;

    constructor(options: ITreeSelectOptions) {
        this.idKey = options?.idKey || 'id';
        this.displayNameKey = options?.displayNameKey || 'display_name';
        this.childrenKey = options?.childrenKey || 'children';
    }
}