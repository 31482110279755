<app-sidebar></app-sidebar>

<div class="main-content">

    <app-header></app-header>

    <main>
        <div class="container-fluid col-xl-11 col-lg-12 mt-4">
            <router-outlet></router-outlet>
        </div>
    </main>

    <div class="container-fluid">
        <app-footer></app-footer>
    </div>

</div>
<!-- <vedrai-page-loader></vedrai-page-loader> -->