
import { TimeSeries, ITimeSeries, IParsedTimeSeries } from "@vendors/modules/ngx-echarts";
import { Variable } from "../../entities/variable/variable.model";
import { SeriesType } from "../echarts-types.model";
import { VariableSeries } from "../series/variable-series.model";

export class VariableTimeSeries extends TimeSeries<Variable> {

    series: VariableSeries[];
    shiftSeriesValue: number;

    constructor(variable?: Variable, timeSeries?: ITimeSeries) {
        super(variable, timeSeries);
    }

    initSeries(parsedTimeSeries: IParsedTimeSeries) {
        const { paramsKeys, timeSeries } = parsedTimeSeries;
        return paramsKeys.map((param: SeriesType) => new VariableSeries({
            id: param,
            variableName: this.entity.name,
            seriesType: param,
            values: timeSeries[param]
        }));
    }

}