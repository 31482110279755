// import { VariableFiltersModalComponent } from "@shared";
import { IModalOptions } from "@vendors/modules/ng-bootstrap";
import { VariableFiltersModalComponent } from "../../widgets/variable-filters-modal/variable-filters-modal.component";

export const SHARED_MODALS_CONFIGS: ModalsConfigsTypes = {
    variableFilters: {
        component: VariableFiltersModalComponent,
        isEmbedded: false,
        size: 'xl'
    }
};

export type SharedModalNamesTypes = keyof typeof SHARED_MODALS_CONFIGS;
export type ModalsConfigsTypes = { [key in string]: IModalOptions<any> };
