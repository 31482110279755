<div class="input-group">
    <input data-j="pt-text-input" class="form-control" [placeholder]="placeholder" type="text" [(ngModel)]="searchText"
        (ngModelChange)="changed($event)">
    <div append class="input-group-append">
        <span *ngIf="!searchText" class="input-group-text">
            <fa-icon [icon]="['fas', 'magnifying-glass']"></fa-icon>
        </span>
        <a *ngIf="searchText?.length > 0" (click)="resetSearch()" class="input-group-text delete-input-search">
            <fa-icon [icon]="['fas', 'xmark-large']"></fa-icon>
        </a>
    </div>
</div>