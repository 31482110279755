import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skltn-table',
  templateUrl: './skltn-table.component.html'
})
export class SkltnTableComponent {

  @Input() set columnsNumber(columnsNumber: number) {
    this.columns = [].constructor(columnsNumber);
  };
  @Input() set rowsNumber(rowsNumber: number) {
    this.rows = [].constructor(rowsNumber);
  };
  @Input() tableClass: string;

  columns: number[];
  rows: number[];
}
