import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ComponentContainerModalComponent } from "./widgets/component-container-modal/component-container-modal.component";
import { NgxTranslateVendorModule } from '../ngx-translate';
import { ModalsService } from "./services/modals.service";

@NgModule({
    imports: [
        NgbModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        NgxTranslateVendorModule
    ],
    declarations: [
        ComponentContainerModalComponent
    ],
    exports: [
        NgbModule,
        ComponentContainerModalComponent
    ]
})
export class NgBootstrapVendorModule {
    static forRoot(): ModuleWithProviders<NgBootstrapVendorModule> {
        return {
            ngModule: NgBootstrapVendorModule,
            providers: [
                ModalsService
            ]
        }
    }
}