
import { deepClone } from "@vedrai/vedrai-core";
import { Filters } from "../../entities/variable/filters.model";
import { FiltersChart } from "./filters-chart.model";

export class FiltersTreeMapChart extends FiltersChart {

    get parent() {
        return this.data;
    }

    constructor(chart, filters: Filters) {
        super(chart);
        this.updateChart(filters);
    }

    updateChart(filters: Filters) {

        const series = this.getSeriesById('treemap');
        this.data = deepClone(filters);
        series.data = this.data.children;
        series.label.formatter = (params) => params.data.display_name;

        this.updateChartInstance();
    }

    initSelections(selectedFilter: Filters, shortcutFilters: Filters[]) {
        this.selectFilter(selectedFilter);
        this.highlight(shortcutFilters);
    }

    updateHighlight(shortcutFilters: Filters[]) {
        this.highlight(shortcutFilters);
        this.updateChartInstance();
    }

    selectFilter(filter: Filters) {

        let filterName = null;

        this.selectedFilter = filter;
        if (filter) {
            const currentFilter = this.treeSearch(filter.id, this.parent);

            if (currentFilter) {
                filterName = currentFilter.name;
            }
        }

        this.instance.dispatchAction({
            type: 'select',
            seriesId: 'treemap',
            name: filterName
        })
    }

    private highlight(shortcutFilters: Filters[], children: Filters[] = this.firstChilden) {
        children.forEach((filter: Filters) => {
            const isShortcut = !!shortcutFilters.find((shortcutFilter) => shortcutFilter.id == filter.id);
            filter.isShortcut = isShortcut;

            if (isShortcut) {
                const color = filter.children?.length ? '#2CA6A4' : '#5ec2b7'
                filter.itemStyle = {
                    color: color,
                    borderColor: color
                }
            } else {
                filter.itemStyle = {
                    color: '#777'
                }
            }
            if (filter.children?.length) {
                this.highlight(shortcutFilters, filter.children);
            }
        });
    }

}