import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared';
import { FontAwesomeVendorModule } from '@vendors/modules/fontawesome';
import { NgBootstrapVendorModule } from '@vendors/modules/ng-bootstrap';
import { NgxTranslateVendorModule } from '@vendors/modules/ngx-translate';
import { VedraiKitVendorModule } from '@vendors/modules/vedrai-kit';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { WorkInProgressComponent } from './pages/work-in-progress/work-in-progress.component';
import { FooterComponent } from './widgets/footer/footer.component';
import { HeaderComponent } from './widgets/header/header.component';
import { MenuModule } from './widgets/menu/menu.module';
import { SidebarComponent } from './widgets/sidebar/sidebar.component';
import { BreadcrumbsService } from './services/breadcrumbs.service';
import { BreadCrumbComponent } from './widgets/bread-crumb/bread-crumb.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    NgxTranslateVendorModule,
    FontAwesomeVendorModule,
    VedraiKitVendorModule,

    SharedModule,
    NgBootstrapVendorModule,
    MenuModule
  ],
  declarations: [
    HeaderComponent,
    BreadCrumbComponent,
    FooterComponent,
    WorkInProgressComponent,
    NotFoundComponent,
    AccessDeniedComponent,
    MainLayoutComponent,
    SidebarComponent
  ],
  exports: [
    WorkInProgressComponent,
    NotFoundComponent,
    AccessDeniedComponent,
    MainLayoutComponent
  ],
  providers: [
    BreadcrumbsService
  ]
})
export class LayoutModule { }
