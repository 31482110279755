<skltn-root>
  <div class="sticky-table table-responsive">
    <table class="table scenarios-table" [class]="tableClass">
      <thead >
        <th>
            <div skltn-bone class="header-column skltn-card-line w-60 m-1"></div>
        </th>
        <th *ngFor="let col of indexesColumns">
          <div class="flex-end">
            <div skltn-bone class="header-column skltn-card-line w-40 m-1"></div>
          </div>
        </th>
      </thead>
      <tbody>
        <tr *ngFor="let row of rows">
          <td>
            <div skltn-bone class="skltn-card-title w-60 mb-0"></div>
          </td>
          <td *ngFor="let col of indexesColumns">
              <div class="flexw-center-end flex-column">
                <div skltn-bone class="skltn-card-title w-80 mb-1"></div>  
                <div skltn-bone class="header-column skltn-card-line w-60 mb-0"></div>
            </div>
          </td>
      </tbody>
    </table>
  </div>
</skltn-root>