import { TableColumn, ITableColumn, TableLabels, ITableLabels, ITableConfigs } from "@vedrai/vedrai-ui";

export interface ITableStickyColumn extends ITableColumn {
    sticky?: 'left' | 'right';

    class: string
    columnClass: string;
}

export class TableStickyColumn extends TableColumn {
    sticky: 'left' | 'right';

    class: string
    columnClass: string;

    constructor(stickyTableColumn: ITableStickyColumn) {
        super(stickyTableColumn);
        Object.assign(this, stickyTableColumn);
    }
}

export interface ITableStickyConfig extends ITableConfigs {
    labelPrefix?: string;
    useSortIndex?: boolean;
    columns: ITableStickyColumn[];
    isCompact: boolean;
    labels?: ITableLabels;
    data?: any[];
}


export class TableStickyConfig implements ITableStickyConfig {
    useSortIndex: boolean;
    columns: ITableStickyColumn[];
    isCompact: boolean;
    labelPrefix?: string;
    labels?: TableLabels;
    data?: any[];

    constructor(configs: ITableStickyConfig) {
        this.useSortIndex = configs.useSortIndex === true;
        this.columns = configs.columns || [];
        this.data = configs.data || [];
        this.labelPrefix = configs.labelPrefix || '';
        this.labels = new TableLabels(configs.labels);
        this.isCompact = configs.isCompact || false;
    }
}

export interface ITableStickyConfigWrapper {
    configs: ITableStickyConfig;
    presentationConfigs?: any;
    rowClass?: Function | string;
}
