import { Series } from "./series.model";

export class TimeSeriesEmpty<T> {

    dates: string[];
    series: Series[];
    entity: T;
    isError: boolean;

    constructor() {
        this.series = [];
        this.dates = [];
        this.isError = true;
    }
}