import { ExtractInstanceType, PeriodClassType, PeriodType, PeriodTypes } from "./period-types.model";
import { Month } from "./month/month.model";
import { PeriodMetadata } from "./period-metadata.model";
import { Quarter } from "./quarter/quarter.model";
import { Year } from "./year/year.model";

// https://www.dofactory.com/javascript/design-patterns/factory-method
// https://medium.com/codex/factory-pattern-type-script-implementation-with-type-map-ea422f38862
export class PeriodsFactory {

    static periods = {
        month: Month,
        quarter: Quarter,
        year: Year
    };

    static createPeriod(period: PeriodTypes, startDate: string, endDate: string): ExtractInstanceType<PeriodClassType> {
        const periodType: PeriodType = this.getPeriodType(period); 
        return new PeriodsFactory.periods[periodType](startDate, endDate);
    }

    static getPeriodsMetadata(periodsTypes: PeriodTypes[]) {

        if (!periodsTypes?.length) return [];

        const metadataPeriods = this.getAllMetadataPeriods();

        const result: PeriodMetadata[] = []
        periodsTypes.forEach((periodType: PeriodTypes) => {
            const periodMetadata = metadataPeriods.find((periodMetadata: PeriodMetadata) =>
                periodMetadata.contains(periodType)
            )
            if (periodMetadata) result.push(periodMetadata);
        })
        return result;
    }

    static getAllMetadataPeriods() {
        return Object.keys(this.periods).map((periodType: PeriodType) =>
            new PeriodMetadata(this.periods[periodType]));
    }

    static getPeriodType(period: PeriodTypes) {
        const metadataPeriods = this.getAllMetadataPeriods();

        return metadataPeriods.find((periodMetadata: PeriodMetadata) =>
            periodMetadata.contains(period)
        )?.periodType;
    }
}

export function AddStaticsToInstance(constructor: Function) {
    const classStaticAttributes = Object.keys(constructor);
    classStaticAttributes.forEach((attributeName) => {
        constructor.prototype[attributeName] = constructor[attributeName];
    });
    Object.seal(constructor);
}