<ng-container *ngFor="let section of sections$ | async">
    <ng-container *ngIf="section.label">
        <hr *ngIf="section.separator" class="my-3">
        <h6 class="navbar-heading text-muted">{{section.label | translate}}</h6>
    </ng-container>

    <ul class="navbar-nav">
        <ng-container *ngFor="let voice of section.voices">
            <ng-container *ngIf="voice.divider">
                <li *featureFlag="voice.featureFlag" class="menu-divider"></li>
            </ng-container>

            <ng-container *ngIf="!voice.divider">
                <li *featureFlag="voice.featureFlag" class="nav-item" [ngClass]="voice.class">
                    <a routerLinkActive="active" [routerLink]="voice.route" (click)="selectVoice(voice)"
                        (keydown.enter)="selectVoice(voice)" class="nav-link">
                        <fa-duotone-icon [icon]="voice.icon" [size]="voice.iconSize"
                            [swapOpacity]="voice.iconSwapOpacity" routerLinkActive="active" class="mr-4">
                        </fa-duotone-icon>
                        {{voice.label | translate}}
                    </a>
                </li>
            </ng-container>

        </ng-container>

        <li *ngFor="let group of section.groups" class="nav-item" [ngClass]="group.class">
            <a class="nav-link" (click)="toggleGroup(group)" data-toggle="collapse"
                [attr.aria-expanded]="currentGroup === group">
                <fa-icon [icon]="group.icon" [size]="group.iconSize" routerLinkActive="active" class="mr-4">
                </fa-icon>
                {{group.label | translate}}
            </a>
            <div class="collapse" [ngbCollapse]="currentGroup !== group">
                <ul class="nav nav-sm flex-column">
                    <li *ngFor="let voice of group.voices" class="nav-item" [ngClass]="voice.class">
                        <a routerLinkActive="active" [routerLink]="voice.route" class="nav-link"
                            (click)="selectVoice(voice)" (keydown.enter)="selectVoice(voice)">
                            {{voice.label | translate}}
                        </a>
                    </li>
                </ul>
            </div>
        </li>


    </ul>
</ng-container>