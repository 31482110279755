import { TimeSeries } from '@vendors/modules/ngx-echarts';
import { Workbook, Worksheet } from 'exceljs';
import { ExcelTemplateOptions } from '../excel-template-options.model';
import { ExcelTemplate } from '../excel-template.mode';
import { TimeSeriesToExcel } from '../time-series-to-excel.model';

export class TimeSeriesTemplate extends ExcelTemplate<any> {

    excelData: TimeSeriesToExcel<any>;
    workBook: Workbook;
    workSheet: Worksheet;

    constructor(timeSeries: TimeSeries<any>[], options: ExcelTemplateOptions) {
        super(timeSeries, options);
    }

    protected fetchHeader() {
        const headerRow = this.workSheet.addRow(this.excelData.headers);

        // TBD
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '4167B8' },
                bgColor: { argb: '' }
            }
            cell.font = {
                bold: true,
                color: { argb: 'FFFFFF' },
                size: 12
            }
        });
    }

    protected fetchRows() {
        // TBD
        this.workSheet.addRows(this.excelData.rows);
    }
}