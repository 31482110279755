import { ITable } from "@vedrai/vedrai-ui";
import { ITableStickyConfig, ITableStickyConfigWrapper, TableStickyColumn, TableStickyConfig } from "./table-sticky-config.model";


export interface ITableSticky extends ITable {
    rowClass?: Function | string;
}

export class TableSticky implements ITableSticky {
    isLoading: boolean;
    configs: TableStickyConfig;
    rowClass?: Function | string;

    constructor(tableStickyConfigs: ITableStickyConfigWrapper, isLoading: boolean = false) {
        const { rowClass, configs, presentationConfigs } = tableStickyConfigs;
        this.configs = this.composeTableConfigs(configs, presentationConfigs || {});

        this.isLoading = isLoading;
        this.rowClass = rowClass;
    }

    composeTableConfigs(configs: ITableStickyConfig, presentationConfigs: any) {
        configs.columns = { ...configs, columns: configs.columns || [] }.columns.map((column) => {
            if (column.id && presentationConfigs[column.id]) {
                return new TableStickyColumn({ ...column, ...presentationConfigs[column.id] })
            }
            return new TableStickyColumn(column);
        });
        return new TableStickyConfig(configs);
    }

    set data(data: any[]) {
        this.setTableData(data);
    }

    get data(): any[] {
        return this.configs.data || [];
    }

    private setTableData(data: any[]) {
        this.configs.data = this.getRowClasses(data);
    }

    private getRowClasses(data: any[]) {
        if (!this.rowClass) return data;
        
        data.forEach((row) => {
            row.rowClass = this.getRowClass(this.rowClass, row);
        });
        return data;
    }

    private getRowClass(rowClass: Function | string, row: any) {
        if (!rowClass) return null;

        return typeof rowClass == 'string' ? rowClass : rowClass(row);
    }

}