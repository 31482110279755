import { formatDate } from '@angular/common';
import { IntelligencesType, PeriodMapType, PeriodSymbolType, PeriodType } from "@models";
import { getStoredLanguage } from '@vendors/modules/ngx-translate';
import moment from "moment";

const dateFormat = 'yyyy-MM-DD';
export const periods: PeriodMapType = {
    M: 'month',
    Q: 'quarter',
    Y: 'year'
};

export function getPeriod(symbol: PeriodSymbolType) {
    return periods[symbol];
}

export function getPeriodSymbol(period: PeriodType) {

    for (let key in periods) {
        if (periods[key] == period) {
            return key;
        }
    }
    return null;
}

/**
 * @returns today
 */
export function today() {
    return moment().format(dateFormat);
}

/**
 * 
 * @param type forecast or real
 * @param period year, month, quarter as string
 * @returns return a start and end date calculated by the period and type
 */
export function getRangeDates(referenceDate: string, type: IntelligencesType, period: PeriodType) {
    let start, end, isReal = type == 'real', referenceDay = moment(referenceDate);

    switch (period) {
        case 'month':
            const previousMonth = referenceDay.clone();
            const nextMonth = referenceDay.clone().add(1, 'M');
            start = isReal ? previousMonth.clone().startOf('M') : nextMonth.clone().startOf('M');
            end = isReal ? previousMonth.clone().endOf('M') : nextMonth.clone().endOf('M');
            break;
        case 'quarter':
            if (isReal) {
                let previousQuarter = referenceDay.clone();
                if (referenceDay.diff(referenceDay.clone().endOf('Q'), 'd') != 0) {
                    previousQuarter = referenceDay.clone().subtract(1, 'Q');
                }
                start = previousQuarter.clone().startOf('Q');
                end = previousQuarter.clone().endOf('Q');
            } else {
                let nextQuarter = referenceDay.clone();
                if (referenceDay.clone().diff(referenceDay.clone().endOf('Q'), 'd') == 0) {
                    nextQuarter = nextQuarter.add(1, 'Q');
                }
                start = nextQuarter.clone().startOf('Q');
                end = nextQuarter.clone().endOf('Q');
            }
            break;
        case 'year':
            if (isReal) {
                start = referenceDay.clone().startOf('year');
                end = referenceDay.clone().endOf('M');
            } else {
                let nextYear = referenceDay.clone();
                if (referenceDay.clone().diff(referenceDay.clone().endOf('year'), 'd') == 0) {
                    nextYear = nextYear.clone().add(1, 'year');
                    start = nextYear.clone().startOf('year');
                    end = nextYear.clone().endOf('year');
                } else {
                    start = referenceDay.clone().startOf('year');
                    end = nextYear.clone().endOf('year');
                }
            }
            break;
    }

    return { start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD') };
}

/**
 * @param quarterIndex index positive for current year and future, negative for < this year
 * @param start boolean for taking first day of quarter or latest day
 * @returns return a date from and index
 */
export function fromQuarterIndexToDate(quarterIndex, start: boolean = true) {
    quarterIndex = quarterIndex - 1;
    const quarter = (quarterIndex < 0 ? (4 + (quarterIndex % 4)) : (quarterIndex % 4)) || 4;
    const yearDiff = Math.ceil(quarterIndex / 4) - 1;
    const year = (new Date()).getFullYear() + yearDiff;

    const date = moment(year, 'YYYY').add(quarter, 'Q');
    return (start ? date.startOf('Q') : date.endOf('Q')).format('YYYY-MM-DD')
}

/**
 * @param date date to use for get quarter can be null for the current date
 * @returns return an object with the selected date quarter and year 
 */
export function getQuarter(date?: string) {
    const parsedDate = date ? moment(date) : moment();
    return {
        date: parsedDate,
        month: parsedDate.month(),
        quarter: parsedDate.quarters(),
        year: parsedDate.year()
    };
}

export function getQuarterIndex(date: string) {
    const { quarter, year } = getQuarter(date);
    return (4 * ((year - new Date().getFullYear()))) + quarter;
}

export function getQuarterIndexForDifect(date: string) {
    const { month, year } = getQuarter(date);
    const quarter = Math.floor((month + 1) / 3);
    return (4 * ((year - new Date().getFullYear()))) + quarter;
}

export function isEndOfQuarter(date: string) {
    return moment(date).endOf('Q').diff(moment(date), 'd') == 0;
}

export function getTime(date?: string) {
    const parsedDate = date ? moment(date) : moment();
    return parsedDate.format('HH:mm')
}

export function getMonthsDifference(startDate: string, endDate: string) {
    const start = moment(startDate);
    const end = moment(endDate);

    return ((end.year() - start.year()) * 12) + end.month() - start.month();
}

export function getDateFromMonthsIndex(date: string, index: number) {
    const startDate = moment(date);
    return startDate.add(index, 'M').endOf('M').format('YYYY-MM-DD');
}

export function getPreviousMonthLastDay(date: string) {
    return moment(date).startOf('M').subtract(1, 'd').format('YYYY-MM-DD');
}

// TODO depracate this
export function getFormattedDate(date: string, format: string) {
    return formatDate(date, format, getStoredLanguage());
}