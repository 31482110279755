export interface IFilters {
    name: string;
    value: string;
    display_name: string;
    children: IFilters[];
}

export class Filters {
    id: string;
    name: string;
    value: number;
    filter_name: string;
    display_name: string;
    collapsed: boolean;
    isShortcut: boolean;
    
    children: Filters[];

    // graphic rappresentation
    itemStyle: any;

    constructor(filter: IFilters) {
        this.name = filter.display_name;
        this.filter_name = filter.name;
        this.display_name = filter.display_name;
        this.value = 1;
        this.isShortcut = false;
        this.id = filter.value;
        this.collapsed = true;

        this.children = filter.children.map((child) => new Filters(child));
       
    }
}


