import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skltn-chart',
  templateUrl: './skltn-chart.component.html'
})
export class SkltnChartComponent {

  @Input() chartName: 'bar-chart' | 'wave-chart' | 'area-chart' | 'linear-wave-chart' | 'target-chart' | 'linear-wave-chart-lg';
  @Input() skltnsSvg: string = 'assets/images/skeleton/skltns-charts.svg'; 
  @Input() h: number = 200; 
  @Input() w: number = 500; 
  @Input() svgH: number = 250; 
  @Input() svgW: number = 2720; 
  @Input() pathH: number = 250; 
  @Input() pathW: number = 600; 

  constructor() { }

}
