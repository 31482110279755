import { getFormattedDate } from '@shared';
import { deepClone } from "@vedrai/vedrai-core";
import { ChartTooltip } from './chart-tooltip.model';
// import { EChartsOption } from "echarts";

export interface IChart {
    // TODO use echarts interfaces
    chart: any;
    instance: any;
}

export class Chart implements IChart {

    initialChart: any;
    chart: any;
    instance: any;
    datesFormat = 'MMM y';
    chartTooltip: ChartTooltip;

    // TODO add usefull echarts configurations
    // TODO add comments for variables with referiment in library online documentation
    // TODO maybe add this class in vedrai core

    get tooltip() {
        return this.chart.tooltip;
    }

    get legend() {
        return this.chart.legend;
    }

    get toolbox() {
        return this.chart.toolbox;
    }

    get dataZoom() {
        return this.chart.dataZoom;
    }

    set dataZoom(dataZoom) {
        this.chart.dataZoom = dataZoom;
    }

    get dataset() {
        return this.chart.dataset.source;
    }

    set dataset(dates) {
        this.chart.dataset.source = dates;
    }

    get series() {
        return this.chart.series;
    }

    set series(series: any[]) {
        this.chart.series = series;
    }

    get xAxis() {
        return this.chart.xAxis;
    }

    get xAxisLabel() {
        return this.xAxis.axisLabel;
    }

    get yAxis() {
        return this.chart.yAxis;
    }

    get yAxisLabel() {
        return this.yAxis.axisLabel;
    }

    get xData() {
        return this.xAxis.data;
    }


    get visualMap() {
        return this.chart.visualMap;
    }

    set visualMap(visualMap) {
        this.chart.visualMap = visualMap;
    }

    get grid() {
        return this.chart.grid;
    }

    get graphic() {
        return this.chart.graphic;
    }

    set graphic(graphic) {
        this.chart.graphic = graphic;
    }


    constructor(chart: any) {
        this.initialChart = deepClone(chart);
        this.chart = deepClone(chart);
        this.chartTooltip = new ChartTooltip();
    }

    setInstance(instance) {
        this.instance = instance;
    }

    resetChart() {
        this.chart = deepClone(this.initialChart);
    }

    updateChartInstance(options: any = {}) {
        if (this.instance) this.instance.setOption(this.chart, options);
    }

    refreshChart() { 
        this.updateChartInstance({notMerge: true});
    }

    getSeriesByindex(index: number) {
        return this.series[index];
    }

    getSeriesById(id: string) {
        return this.series.find((series) => series.id == id);
    }

    setSeriesById(id: string, series: any) {
        const serieIndex = this.series.findIndex((series) => series.id == id);
        if (serieIndex != -1) {
            this.series[serieIndex] = series;
        } else {
            this.series.push(series);
        }
    }

    toggleLegend(serieId: string) {
        const currentSerie = this.getSeriesById(serieId);
        if (!(this.legend?.selected && currentSerie)) return;

        this.legend.selected[currentSerie.name] = !this.legend.selected[currentSerie.name];
        this.updateChartInstance();
    }

    showLegend(serieId: string, show: boolean) {
        const currentSerie = this.getSeriesById(serieId);
        if (!(this.legend?.selected && currentSerie)) return;

        this.legend.selected[currentSerie.name] = show;
        this.updateChartInstance();
    }

    formatXAxisDatesLabels(format: string) {
        // TODO add cross tooltip label formatting 
        let xAxis = this.xAxis;
        if (!Array.isArray(xAxis)) {
            xAxis = [this.xAxis];
        }

        xAxis.forEach((axis) => {
            if (!axis.axisLabel) {
                axis.axisLabel = {};
            }
            axis.axisLabel.formatter = (date) => getFormattedDate(date, format);
        })
    }

    protected getDecalStyle() {
        return {
            dashArrayX: [1, 0],
            dashArrayY: [4, 3],
            symbolSize: .8,
            rotation: Math.PI / 3
        };
    }

}