import { NgModule } from "@angular/core";
import { FileSaverModule } from 'ngx-filesaver';

@NgModule({
    imports: [
        FileSaverModule 
    ],
    exports: [
        FileSaverModule 
    ]
})
export class FileSaverVendorModule {}