import { TranslateService } from "@ngx-translate/core";
import { AppInjector } from "@vedrai/vedrai-core";
import { ISeries, Series } from "@vendors/modules/ngx-echarts";
import { SeriesGroupsType, SeriesIdType, SeriesType, SidesType } from "../echarts-types.model";

export interface IVariableSerie extends ISeries {

    id: SeriesIdType;
    variableName: string;
    seriesType: SeriesType;
    label?: string;
    selected?: boolean;
    group?: SeriesGroupsType;
    values: any;
}

export class VariableSeries extends Series {

    id: SeriesIdType;
    variableName: string;
    seriesType: SeriesType;
    selected: boolean;
    group: SeriesGroupsType;
    label: string;
    sideType?: SidesType;

    #translate: TranslateService;

    constructor(series: IVariableSerie) {
        super(series);
        this.#translate = AppInjector.get(TranslateService);

        this.variableName = series.variableName;
        this.seriesType = series.seriesType;
        this.selected = this.seriesType == 'forecast' || this.seriesType == 'real';
        this.group = this.getSeriesGroup(this.seriesType);
        this.label = <string>this.#translate.instant(`examine.params.${this.seriesType}`);
    }

    getSeriesGroup(seriesType: SeriesType): SeriesGroupsType {

        if (seriesType == 'forecast' || seriesType == 'real') {
            return 'real';
        }
        return seriesType != 'all' ? seriesType : 'real';
    }
}