import {
    Component, Input, ChangeDetectionStrategy, TemplateRef, Output, EventEmitter,
    DoCheck, ChangeDetectorRef, ViewChild, ElementRef, HostBinding, OnDestroy
} from '@angular/core';
import { TableLabels, TableOrderBy } from '@vedrai/vedrai-ui';
import { fromEvent, Subscription } from 'rxjs';
import { TableCollapsableColumn, TableCollapsableConfig } from '../../models/table-collapsable/table-collapsable-config.model';
import { TreeSelect } from '../../models/tree-select/tree-select.model';

@Component({
    selector: 'app-table-collapsable',
    templateUrl: './table-collapsable.component.html',
    styleUrls: ['./table-collapsable.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableCollapsableComponent implements DoCheck, OnDestroy {

    expandedRow: any = null;

    private resizeSubscription: Subscription;

    private _configs: TableCollapsableConfig;

    private _columns: TableCollapsableColumn[];

    private _data: any[];

    private updateStickyOptions = false;

    @Input() configs: TableCollapsableConfig;

    @Input() compact: boolean;

    @Input() inLoading = false;

    // HEADER
    @Input() headerTemplate: TemplateRef<any>;

    // EXPANDED ROW
    @Input() expandedRowTemplate: TemplateRef<any>;

    @Input() tableClass: string;

    @Input() theadClass: string;

    @Input() index: number = 1;

    @Input() selectedRow: any;

    @Output() orderBy: EventEmitter<TableOrderBy>;
    @Output() selectRow: EventEmitter<any>;

    @ViewChild('stickyTable', { static: true }) stickyTable: ElementRef;

    @HostBinding("style") scrollbarMargins = {
        '--data-scrollbar-margin-left': 0,
        '--data-scrollbar-margin-right': 0,
        '--data-scrollbar-margin-top': 0
    };

    get isCompact(): boolean {
        return typeof this.compact == 'boolean' ? this.compact : this.configs.isCompact;
    }

    get labelPrefix(): string {
        return this.configs.labelPrefix ? this.configs.labelPrefix + '.' : '';
    }

    get labels(): TableLabels {
        return this.configs.labels || null;
    }

    get useSortIndex(): boolean {
        return this.configs.useSortIndex;
    }

    get rows(): any[] {
        return this._data;
    }

    get columns(): TableCollapsableColumn[] {
        return this._columns;
    }

    set columns(columns: TableCollapsableColumn[]) {
        this._columns = columns;
    }

    get isCollapsedKey() {
        return this.configs.isCollapsedKey;
    }

    get childrenKey() {
        return this.configs.childrenKey;
    }

    get isSelectable() {
        return this.configs.isSelectable;
    }

    constructor(private ref: ChangeDetectorRef) {
        this.orderBy = new EventEmitter();
        this.selectRow = new EventEmitter();

        this.resizeSubscription = fromEvent(window, 'resize')
            .subscribe(evt => this.setStickyOption());
    }

    ngDoCheck() {
        if (this.configs != this._configs) {
            this._configs = this.configs;
            this.storeColumns();
        }

        if (this.configs.data != this._data) {
            this.updateStickyOptions = true;
            this._data = this.configs.data;
            this.ref.detectChanges();
        }
    }

    ngAfterViewChecked() {
        if (!this.shouldSetStickyOptions()) return;

        this.setStickyOption();
        this.updateStickyOptions = false;
        this.ref.detectChanges();
    }

    ngOnDestroy(): void {
        this.resizeSubscription.unsubscribe();
    }

    onSelectRow(row: TreeSelect<any>) {
        if (row.children?.length) return;
        this.selectedRow = row;
        this.selectRow.emit(row);
    }

    onToggleRow(row: TreeSelect<any>) {
        row.isCollapsed = !row.isCollapsed;
    }

    switchColumn(column: TableCollapsableColumn, mansion?: string) {
        switch (mansion) {
            case 'expand':
                return column.canExpandRow && !column.selectable;
            case 'select':
                return column.selectable && !column.canExpandRow;
            default:
                return !column.canExpandRow && !column.selectable;
        }
    }

    switchColumnHeader(column: TableCollapsableColumn, mansion?: string) {
        switch (mansion) {
            case 'select':
                return column.selectable && !column.toggleSelection;
            default:
                return !column.selectable && !column.toggleSelection;
        }
    }

    public expandRow(row: any) {
        this.expandedRow = this.expandedRow === row ? null : row;
    }

    public changeOrderByDirection(column: TableCollapsableColumn, index: number) {
        if (column.sortable) {
            column.sort = column.sort == 'DESC' ? 'ASC' : (column.sort == 'ASC' ? null : 'DESC');
            this.orderBy.emit({
                field: column.id,
                direction: column.sort,
                sortIndex: this.useSortIndex ? column.sortIndex : index
            });
        }
    }

    /**
     * use this funtion to store current columns configs in order
     * to apply a correct refresh on current configs and not on initialization configs
     */
    public storeColumns() {
        if (this.configs) {
            this.columns = this.configs.columns.map((column: TableCollapsableColumn) => {
                return new TableCollapsableColumn(column);
            });
        }
    }

    /**
     * refresh component by apply again you initial columns configurations 
     */
    public refresh() {
        this.storeColumns();
    }

    private shouldSetStickyOptions() {
        const tableColumns = this.getTableColumns();
        return this.updateStickyOptions && this.rows.length > 0 && tableColumns.length == this.columns.length
    }

    private setStickyOption() {
        const scrollbarMargins = { left: 0, right: 0, top: 0 };

        const tableColumns = this.getTableColumns();

        scrollbarMargins.top = tableColumns[0].clientHeight;

        const accumulator = {
            left: 0,
            right: this.getTableWidth(tableColumns)
        }

        for (let i = 0; i < tableColumns.length; i++) {
            const th: HTMLElement = <HTMLElement>tableColumns[i];
            const sticky = this.getStickyAttribute(th);
            const width = th.clientWidth;

            accumulator.right -= width;
            if (sticky) {
                const stickyValue = sticky.nodeValue;
                this.columns[i][stickyValue] = accumulator[stickyValue];
                scrollbarMargins[stickyValue] += width;
            }
            accumulator.left += width;
        }

        const { left, right, top } = scrollbarMargins;
        this.setScrollbarMargin(left, right, top);
    }

    private getTableWidth(tableColumns: Element[]) {
        return tableColumns.reduce((res, column) => res + column.clientWidth, 0)
    }

    private getTableColumns() {
        const tableElement = this.stickyTable.nativeElement;
        return Array.from(this.getTableHeader(tableElement).children);
    }

    private getTableHeader(table: HTMLTableElement) {
        return table.rows[0];
    }

    private getStickyAttribute(el: HTMLElement) {
        return el.getAttributeNode('sticky');
    }

    private setScrollbarMargin(left: number, right: number, top: number) {
        this.scrollbarMargins = {
            '--data-scrollbar-margin-left': left,
            '--data-scrollbar-margin-right': right,
            '--data-scrollbar-margin-top': top
        };
    }

}
