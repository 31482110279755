import { Component, EventEmitter, Input, Output } from "@angular/core";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
    selector: 'app-search-input',
    templateUrl: './search-input.component.html',
})
export class SearchInputComponent {

    @Input() searchText: string;
    @Input() placeholder: string;

    @Output() changeSearch: EventEmitter<string>;

    private searchTextChanged = new Subject<string>();

    constructor() {
        this.changeSearch = new EventEmitter<string>();
        this.searchTextChanged.pipe(
            debounceTime(300),
            distinctUntilChanged()
        ).subscribe(() => {
            this.changeSearch.emit(this.searchText);
        });
    }

    resetSearch() {
        this.searchText = '';
        this.changeSearch.emit(this.searchText);
    }

    changed(searchText: string) {
        this.searchTextChanged.next(searchText);
    }

}