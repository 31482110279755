import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeVendorModule } from '@vendors/modules/fontawesome';
import { NgBootstrapVendorModule } from '@vendors/modules/ng-bootstrap';
import { VedraiKitVendorModule } from '@vendors/modules/vedrai-kit';
import { NgxTranslateVendorModule } from '@vendors/modules/ngx-translate';
import { AppsMenuComponent } from './apps-menu/apps-menu.component';
import { MenuComponent } from './menu/menu.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { NotificationsMenuComponent } from './notifications-menu/notifications-menu.component';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgxTranslateVendorModule,
        FontAwesomeVendorModule,
        NgBootstrapVendorModule,
        VedraiKitVendorModule
    ],
    declarations: [
        MenuComponent,
        UserMenuComponent,
        AppsMenuComponent,
        NotificationsMenuComponent
    ],
    exports: [
        MenuComponent,
        UserMenuComponent,
        AppsMenuComponent,
        NotificationsMenuComponent
    ]
})
export class MenuModule { }
