import { Component } from '@angular/core';

@Component({
  selector: 'app-work-in-progress',
  templateUrl: './work-in-progress.component.html'
})
export class WorkInProgressComponent {
  constructor() {
  }
}
