import { VariableTypes } from "./variable-types";

export interface IVariable {
    id: string;
    name?: string;
    description?: string;
    display_name: string;
    type?: VariableTypes;
    unit_meas?: string;
    has_tags?: boolean; // TBD
}

export class Variable {
    id: string;
    name: string;
    description: string;
    display_name: string;
    type: VariableTypes;
    unit_meas: string;
    has_tags: boolean;

    constructor(variable: IVariable, type: VariableTypes) {
        this.id = variable.id;
        this.name = variable.name;
        this.description = variable.description;
        this.display_name = variable.display_name;
        this.type = type || variable.type;
        this.has_tags = variable.has_tags;
        this.unit_meas = variable.unit_meas;
    }
}

export interface IKpi extends IVariable {
}

export interface IVariableCode {
    type: VariableTypes;
    id: string;
}