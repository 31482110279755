import { ISeries, Series } from "@vendors/modules/ngx-echarts";

export interface IScenarioSerie extends ISeries {
    label: string;
}

export class ScenarioSeries extends Series {

    label: string;

    constructor(series: IScenarioSerie) {
        super(series);
        this.label = series.label;
    }
}