import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { NgxTranslateVendorModule } from '../../../ngx-translate/ngx-translate.vendor.module';
import { FontAwesomeVendorModule } from '../../../fontawesome/fontawesome.vendor.module';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalNotification } from '../../models/notification/modal-notification.model';

@Component({
    selector: 'app-notification-modal',
    styleUrls: ['./notification-modal.component.scss'],
    templateUrl: './notification-modal.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FontAwesomeVendorModule,
        NgxTranslateVendorModule
    ]
})
export class NotificationModalComponent {

    @Input() data: ModalNotification;

    get icon() {
        return this.data?.icon || ['fas', 'exclamation'];
    }

    get title(): string {
        return this.data?.title || 'Warning';
    }

    get subtitle(): string {
        return this.data?.subtitle || '';
    }

    get description(): string {
        return this.data?.description || '';
    }

    get modalBtn(): string {
        return this.data?.modalBtn;
    }

    constructor(private activeModalService: NgbActiveModal,
        private router: Router) { }

    ngOnInit() {
        console.log(this.data)
    }

    onClose() {
        if (this.data.redirectTo) {
            this.router.navigate([this.data.redirectTo]);
        }
        this.activeModalService.close();
    }

}