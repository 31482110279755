<div class="table-container">

    <div class="sticky-table collapsable-table" [class]="isCompact ? 'card-table-responsive' : 'table-responsive'"
        aria-live="polite" [ngStyle]="{'min-height': inLoading ? '30rem' : 'auto'}">
        <table #stickyTable class="table" [class.card-list-table]="isCompact" [class]="tableClass || 'table-stripped'">
            <thead *ngIf="!headerTemplate" [class]="theadClass || 'thead-light'">
                <tr>
                    <ng-container *ngFor="let column of columns; let i = index">
                        <th scope="col" [attr.sticky]="column.sticky || null" [class]="column.class"
                            [style.min-width]="column.width ? column.width + 'px' : null"
                            [style]="column.sticky ?  '--data-sticky-offset:'+ column[column.sticky] : null"
                            [ngClass]="column.sticky ? column.sticky +'-sticky-th': null">
                            <div *ngIf="column | check : switchColumnHeader" role="button" class="header-column"
                                [class.sortable]="column.sortable" [attr.tabindex]="column.sortable ? index : null"
                                [attr.aria-label]="((labels?.sort || 'Order column') | translate) + (column.sort ? (' ' + column.sort) : '')"
                                (click)="changeOrderByDirection(column, i)"
                                (keydown.enter)="changeOrderByDirection(column, i)">
                                <span>
                                    {{(labelPrefix + (column.label || column.id)) | translate}}
                                </span>
                            </div>
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <ng-container *ngIf="headerTemplate">
                <ng-container *ngTemplateOutlet="headerTemplate; context: { $implicit: columns }"></ng-container>
            </ng-container>
            <tbody>

                <ng-container *ngTemplateOutlet="recursiveRows; context: { $implicit: rows, level: 0 }"></ng-container>

                <tr *ngIf="!rows.length && !inLoading">
                    <div class="no-results">
                        {{(labels?.noResults || 'No results') | translate}}
                    </div>
                </tr>
            </tbody>
        </table>

    </div>

    <vedrai-local-loader [inLoading]="inLoading"></vedrai-local-loader>

</div>


<ng-template #recursiveRows let-rows let-level="level">
    <ng-container *ngFor="let row of rows; let j = index;">
        <tr [ngClass]="row[childrenKey]?.length ? 'parent-row': 'child-row' " [style]="'--data-level:'+ level"
            [class.selected]="selectedRow == row" [class.cursor-pointer]="isSelectable && !row[childrenKey]?.length">
            <td *ngFor="let column of columns; let i = index;" (click)="onSelectRow(row)"
                [attr.sticky]="column.sticky || null"
                [style]="column.sticky ?  '--data-sticky-offset:'+ column[column.sticky] : null"
                [style.min-width]="column.width ? column.width + 'px' : null"
                [ngClass]="column.sticky ? column.sticky +'-sticky-td': null" [class]="column?.class">

                <ng-container *ngIf="!column.templateContent && (row[column.id] | parse: column.transform) as value">

                    <div *ngIf="row[childrenKey]?.length && i==0" class="collapsable-cell" >

                        <button class="toggle-row-button" (click)="onToggleRow(row)">
                            <ng-container [ngTemplateOutlet]="row.isCollapsed ? openIcon: closeIcon"></ng-container>
                        </button>
                        <span>{{ column.translate ? (value | translate) : value }}</span>
                    </div>

                    <span *ngIf="!row[childrenKey]?.length || i != 0" [class]="column?.class">
                        {{ column.translate ? (value | translate) : value}}</span>
                </ng-container>

                <ng-container *ngIf="column.templateContent">
                    <ng-template [ngTemplateOutlet]="column.templateContent"
                        [ngTemplateOutletContext]="{ $implicit: row, value: row[column.id], key: column.id, index: j, columnIndex: i }">
                    </ng-template>
                </ng-container>
            </td>
        </tr>
        <ng-container *ngIf="row[childrenKey]?.length && !row.isCollapsed">
            <ng-container *ngTemplateOutlet="recursiveRows; context:{ $implicit: row[childrenKey], level: level+1 }">
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #openIcon>
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7 3.25H4.75V1C4.75 0.723906 4.52609 0.5 4.25 0.5H3.75C3.47391 0.5 3.25 0.723906 3.25 1V3.25H1C0.723906 3.25 0.5 3.47391 0.5 3.75V4.25C0.5 4.52609 0.723906 4.75 1 4.75H3.25V7C3.25 7.27609 3.47391 7.5 3.75 7.5H4.25C4.52609 7.5 4.75 7.27609 4.75 7V4.75H7C7.27609 4.75 7.5 4.52609 7.5 4.25V3.75C7.5 3.47391 7.27609 3.25 7 3.25Z"
            fill="#8EA2B5" />
    </svg>
</ng-template>

<ng-template #closeIcon>
    <svg width="8" height="2" viewBox="0 0 8 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7 0.25C3.75255 0.25 4.66154 0.25 1 0.25C0.723906 0.25 0.5 0.473906 0.5 0.75V1.25C0.5 1.52609 0.723906 1.75 1 1.75C4.70235 1.75 3.72626 1.75 7 1.75C7.27609 1.75 7.5 1.52609 7.5 1.25V0.75C7.5 0.473906 7.27609 0.25 7 0.25Z"
            fill="#8EA2B5" />
    </svg>
</ng-template>