import foreseeTheme from '@configs/charts/themes/foresee.theme.json';
import vedraiTheme from '@configs/charts/themes/vedrai.theme.json';
import examineTheme from '@configs/charts/themes/examine.theme.json';
import scenariosTheme from '@configs/charts/themes/scenarios.theme.json';
import scenarioAnalysisTheme from '@configs/charts/themes/scenario-analysis.theme.json';

export const chartsThemes = [
    {
        name: 'vedrai',
        theme: vedraiTheme
    },
    {
        name: 'foresee',
        theme: foreseeTheme
    },
    {
        name: 'examine',
        theme: examineTheme
    },
    {
        name: 'scenarios',
        theme: scenariosTheme
    }, {
        name: 'scenario-analysis',
        theme: scenarioAnalysisTheme
    }
]
