import { Injectable } from '@angular/core';
import { deepClone, get } from '@vedrai/vedrai-core';

import tablesConfigs from '@configs/tables/tables.json';

export type TablesType = 'reports' | 'strategies' | 'scenarios' | 'scenario-updates' | 'scenario-variable-values' | 'cash-flow' | 'index' | 'strategy-reports';

@Injectable()
export class TablesService {

    getTableConfigs(type: TablesType) {
        return deepClone(get(tablesConfigs, type))
    }
}