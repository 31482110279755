import { IVariable, Variable } from "../variable/variable.model";
import { ISentimentPeriod, IParsedSentiment } from "./sentiments.model";

export interface IKpiSentiment extends IVariable {

    description: string;
    weight: number;
    label: string;
    good_when: string;
    unit: string;
    aggregate_type: string;
    period: ISentimentPeriod;
    sentiment: number;

     // Added to show old sentiment in forecast
     old_sentiment?: number;

     // Added to show colors
     color?: string;
     colors?: string[];
 
     // Added to show current sentiment selected by unit
     current?: IParsedSentiment;
}

export class KpiSentiment extends Variable {
    display_name: string;
    description: string;
    id: string;
    weight: number;
    label: string;
    good_when: string;
    aggregate_type: string;
    period: ISentimentPeriod;
    sentiment: number;

    // Added to show old sentiment in forecast
    old_sentiment?: number;

    // Added to show colors
    color?: string;
    colors?: string[];

    // Added to show current sentiment selected by unit
    current?: IParsedSentiment;

    constructor(sentiment: IKpiSentiment) {
        super(sentiment, 'kpis');
        this.unit_meas = sentiment.unit;
        
        this.description = sentiment.description;
        this.weight = sentiment.weight;
        this.label = sentiment.label;
        this.good_when = sentiment.good_when;
        this.aggregate_type = sentiment.aggregate_type;
        this.period = sentiment.period;
        this.sentiment = sentiment.sentiment;
    }

}