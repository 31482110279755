import {
  Component, EventEmitter, Input,
  OnChanges, Output, ViewChild
} from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll';

@Component({
  selector: 'app-series-selector',
  templateUrl: './series-selector.component.html',
  styles: [
    `:host{ position: relative; display: block; }`
  ]
})
export class SeriesSelectorComponent implements OnChanges {

  @ViewChild('nav', { read: DragScrollComponent }) ds: DragScrollComponent;

  @Input() colors: string[];
  @Input() series: any[];
  @Input() selectedId: string;

  @Output() selectSeries: EventEmitter<any>;

  constructor() {
    this.selectSeries = new EventEmitter();
  }

  ngOnChanges(changes) {
    if (changes.series) {
      this.series = changes.series.currentValue.map((series, index) => ({
        ...series,
        color: this.colors ? this.colors[index] : 'var(--primary)',
        checked: (this.selectedId ? this.selectedId == series.id : true)
      }));
    }
  }

  moveLeft() {
    this.ds.moveLeft();
  }

  moveRight() {
    this.ds.moveRight();
  }

  moveTo(index) {
    this.ds.moveTo(index);
  }

  onToggle(series: any) {

    series.checked = !series.checked;

    this.selectSeries.emit(series);
  }

}
