import config from '../../package.json';
const { name, version } = config;

export const appName = name;
export const environment = {
  production: true,
  name: 'dev',
  version,
  urlApiSSO: 'https://sso.dev.vedr.ai/api/sso',
  urlApiPrivate: 'https://adm.james.dev.vedr.ai/api',
  urlApiPublic: 'https://adm.james.dev.vedr.ai/api',
  urlApiNotifications: 'https://api.infer.dev.vedr.ai/notifications/v1',
  urlMarketplace: 'https://vedrai.com/soluzioni/james/#title-5',
  broker: {
    debug: true,
    url: 'wss://notifications.vedrai.io:15674/ws',
    virtualHost: 'dev',
    topic: 'james'
  },
  sentry: {
    dns: 'https://eb18ebcf2f6b45228ab0188103d2d27b@o477887.ingest.sentry.io/6116217',
    env: 'dev',
    tracingOrigins: ['https://adm.james.vedr.ai'],
    tracesSampleRate: 0.1
  },
  datadog: {
    applicationId: '7a0f41b2-3651-4e25-b703-bcbbc19643df',
    clientToken: 'pubefa4e08820e6110e0d3e90074e56e418',
    site: 'datadoghq.eu',
    service:'vedrai-james',
    env:'development',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow' // 'allow' | 'mask' | 'mask-user-input'
  },
  googleAnalytics: {
    trackingCode: 'G-NJRYEZM5GF',
    initCommands: null,
    uri: null,
    enableTracing: true
  },
  languages: ['it', 'es', 'en']
};
