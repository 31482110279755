import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "./utilities/pipes/pipes.module";

@NgModule({
    imports: [
        TranslateModule,
        PipesModule
    ],
    exports: [
        TranslateModule,
        PipesModule
    ]
})
export class NgxTranslateVendorModule {
}