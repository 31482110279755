import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-confirm-action-modal',
    templateUrl: './confirm-action-modal.component.html'
})
export class ConfirmActionModalComponent {

    @Input() initialData: any;

    get confirmActionMessage() {
        return this.initialData.confirmActionMessage;
    }

    get confirm() {
        return this.initialData.confirm;
    }

    get entity() {
        return this.initialData.entity;
    }

    constructor(private activeModalService: NgbActiveModal) {
    }

    onConfirm() {
        this.confirm(this.entity);
        this.activeModalService.close();
    }

    onClose() {
        this.activeModalService.close();
    }

}