import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Filters } from '@models';
import { ModalsService } from '@vendors/modules/ng-bootstrap';
import { deepClone } from '@vedrai/vedrai-core';
import { ModalOptionsConfigs } from '@vendors/modules/ng-bootstrap';

@Component({
    selector: 'app-filters-selector',
    templateUrl: './filters-selector.component.html'
})
export class FiltersSelectorComponent implements OnChanges {

    @Input() showToggle: boolean = false;
    @Input() showFilters: boolean = true;
    @Input() filters: Filters;
    @Input() selectedFilter: Filters;
    @Output() filterChange: EventEmitter<Filters>;
    @Output() selectFilter: EventEmitter<Filters>;
    @Output() toggleFilter: EventEmitter<boolean>;
    @Output() showFiltersChange: EventEmitter<boolean>;

    shortcutFilters: Filters[] = [];

    get parent() {
        return this.filters;
    }

    get firstLevelFilters() {
        return this.filters?.children || [];
    }

    constructor(private modalsService: ModalsService) {
        this.filterChange = new EventEmitter(null);
        this.selectFilter = new EventEmitter(null);
        this.toggleFilter = new EventEmitter(null);
        this.showFiltersChange = new EventEmitter(null);
    }

    ngOnChanges(changes: SimpleChanges) {

        const { filters, selectedFilter } = changes;

        if (filters?.currentValue) {
            this.shortcutFilters = deepClone(this.firstLevelFilters);
        }
        if (selectedFilter?.currentValue) {
            this.selectFirstFiltersLevel();
        }
    }

    selectFirstFiltersLevel() {
        const firstLevelSelectedFilter = this.shortcutFilters.find(
            (shortcutFilter) => shortcutFilter.id == this.selectedFilter.id
        );
        if (!firstLevelSelectedFilter && this.selectedFilter.id != this.filters.id) {
            this.shortcutFilters.push(this.selectedFilter);
        }
    }

    onToggleFilters(selected: boolean) {
        this.showFilters = selected;
        this.showFiltersChange.emit(selected);
        this.toggleFilter.emit(selected);
    }

    openFiltersSelection() {
        const ref = this.modalsService.openSharedModal('variableFilters',
            new ModalOptionsConfigs({
                data: {
                    filters: this.filters,
                    selectedFilter: this.filters.id == this.selectedFilter?.id ? null : this.selectedFilter,
                    shortcutFilters: this.shortcutFilters
                },
                size: 'xl'
            }));

        ref.closed.subscribe((res) => {
            if (!res) return;
            const { filter, shortcutFilters = [] } = res;

            this.shortcutFilters = shortcutFilters;
            this.onSelectFilter(filter);

        });
    }

    toggleSelection(filter: Filters) {
        this.onSelectFilter(filter.id == this.selectedFilter?.id ? this.parent : filter);
    }

    onSelectFilter(filter: Filters) {
        this.selectedFilter = filter;
        this.selectFilter.emit(filter);
        this.filterChange.emit(filter);
    }
}