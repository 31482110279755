import { ModuleWithProviders, NgModule } from "@angular/core";
import { FileSaverVendorModule } from '../file-saver';
import { ExportExcelService } from './services/export-excel.service';

@NgModule({
    imports: [FileSaverVendorModule]
})
export class ExceljsVendorModule {
    static forRoot(): ModuleWithProviders<ExceljsVendorModule> {
        return {
            ngModule: ExceljsVendorModule,
            providers: [
                ExportExcelService
            ]
        }
    }
}