import { IKpiSentiment } from "./kpi-sentiment.model";
import { IVariableSentiment } from "./variable-sentiment.model";

export interface ISentiments {
    global: IGlobalSentiment;
    kpis: IKpiSentiment[];
    variables: IVariableSentiment[];
}

export interface IGlobalSentiment {
    sentiment: number;
    sentiment_average: number;

    // Added to show old sentiment in forecast
    old_sentiment?: number;
    old_sentiment_average?: number;

    // Added to show colors
    color?: string;
    averageColor?: string;
}

export interface ISentimentPeriod {
    value: number;
    last_value: number;
    last_period_value: IAnalysisPeriod;
    same_period_last_year_value: IAnalysisPeriod;
    amount_difference: ISentimentValue;
    percentage_difference: ISentimentValue;
}

export interface IAnalysisPeriod {
    start_date: string;
    end_date: string;
}

export interface ISentimentValue {
    last_period_value: number;
    same_period_last_year_value: number;
}

export interface IParsedSentiment {
    last_value: number;
    valuePeriods: IAnalysisPeriod;
    lastYearValuePeriods: IAnalysisPeriod;
    value: number;
    lastYearValue: number;
    isValueGood: boolean;
    isLastYearValueGood: boolean;
}