import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform } from "@angular/core";
import moment from 'moment';

// TODO refactoring, create a usefull shared pipe
@Pipe({ name: 'momentDate' })
export class MomentDatePipe implements PipeTransform {

  constructor(private translateService: TranslateService) { }

  transform(date: string, format: string): string {
    const momentDate = moment(date);

    if (format == 'Q') {
      return this.translateService.instant('periods.numbered-quarter', {
        quarter: momentDate.quarter()
      })
    }

    if (format == 'QY') {
      return this.translateService.instant('periods.numbered-year-quarter', {
        quarter: momentDate.quarter(),
        year: momentDate.year()
      })
    }

    return momentDate.format(format);
  }
}