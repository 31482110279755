import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({ name: 'quarter' })
export class QuarterPipe implements PipeTransform {
  transform(quarterIndex: number, date?: string): string {
    const quarter = (quarterIndex < 0 ? (4 + (quarterIndex % 4)) : (quarterIndex % 4)) || 4;
    const yearDiff = Math.ceil(quarterIndex / 4) - 1;
    const year = (date ? (moment(date).year() + 1) : moment().year()) + yearDiff;
  
    return `${Math.abs(quarter)}° Trimestre ${year}`
  }
}