<div class="form-group mb-0">
    <div *ngIf="showToggle" class="flex-between-center">
        <label class="form-control-label">{{ 'filters.show-filters' | translate }}
        </label>
        <div class="form-group ml-2 mb--1">
            <label class="custom-toggle custom-toggle-primary">
                <input type="checkbox" [(ngModel)]="showFilters" (ngModelChange)="onToggleFilters($event)">
                <span class="custom-toggle-slider rounded-circle" [attr.data-label-off]="'operations.no' | translate"
                    [attr.data-label-on]="'operations.yes' | translate">
                </span>
            </label>
        </div>
    </div>
    <label *ngIf="!showToggle" class="form-control-label">{{ 'filters.filters' | translate }}
    </label>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="!showFilters">
        <div class="card filters-card" [class.disabled]="!filters?.children?.length" [class.full-height]="showToggle">
            <div *ngIf="filters?.children?.length; else noFilters" class="flexw-start-center">
                <span *ngFor="let filter of shortcutFilters" class="filter-badge" (click)="toggleSelection(filter)">
                    <span class="badge badge-sm cursor-pointer" role="button"
                        [ngClass]="filter.id == selectedFilter?.id ? 'badge-primary': 'badge-light-info'">{{filter.display_name}}
                    </span>
                </span>
                <div class="filter-badge">
                    <span class="badge badge-light-info badge-sm cursor-pointer" role="button"
                        (click)="openFiltersSelection()">
                        <fa-icon [icon]="['fas', 'pen']" class="mr-1"></fa-icon>
                        {{('operations.edit' | translate) | lowercase}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #noFilters>
    <div class="text-sm">{{ 'filters.filters-not-available' | translate }}</div>
</ng-template>