import { formatNumber } from '@angular/common';
import { getStoredLanguage } from '@vendors/modules/ngx-translate';

export function numberNormalization(number: number) {
    return formatNumber(roundNumber(number), getStoredLanguage());
}

export function roundNumber(number: number) {
    if (number > 10000 || number < -10000) {
        return Math.round(number);
    }
    return number;
}

export function roundDecimal(num: number): number {
    if (decimalsAreToLow(num)) {
        return Math.trunc(num);
    }

    if (decimalsAreToHigh(num)) {
        return Math.ceil(num);
    }

    return +(num.toFixed(2))
}

function decimalsAreToLow(num: number): boolean {
    const truncatedNum = Math.trunc(num);
    const onlyTwoDecimalsNumber = Math.floor(num * 100) / 100;
    return truncatedNum === onlyTwoDecimalsNumber;
}

function decimalsAreToHigh(num: number): boolean {
    return num % 1 > 0.99;
}

export function formatNumberWith2Decimals(number: number): string {
    return formatNumber(roundDecimal(number), getStoredLanguage(), '1.2-2');
}