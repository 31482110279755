import {
    faChartUser,
    faChartPie,
    faMagnifyingGlass,
    faQuestion,
    faAreaChart,
    faLaptopArrowDown,
    IconDefinition,
    faBullseyeArrow
} from '@fortawesome/pro-duotone-svg-icons';

export const fad: IconDefinition[] = [
    faChartUser,
    faChartPie,
    faMagnifyingGlass,
    faQuestion,
    faAreaChart,
    faLaptopArrowDown,
    faBullseyeArrow
];