import { INotification } from './notification.model';
import { Notification } from './notification.model';

export class BasicNotification extends Notification {

    status: string;

    get id(): string {
        return this.value?.id;
    }

    get entityType(): string {
        return this.value?.entityType || 'default';
    }

    get display_name(): string {
        return this.value?.display_name;
    }

    get title(): string {
        return this.config?.title;
    }

    get description(): string {
        return this.config?.description;
    }

    get notify(): string {
        return this.config?.notify;
    }

    protected get config() {
        return this.configs[this.entityType][this.status];
    }

    constructor(notification: INotification) {
        super(notification);

        if (!notification?.value) return;
        const { status } = notification.value;

        this.status = status || '404';
    }
}