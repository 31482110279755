import {
    faCircleInfo,
    faCommentDots,
    faLightbulb,
    faCheckSquare,
    faSquare,
    IconDefinition,
    faCircle1,
    faCircle2,
    faCircle3
} from '@fortawesome/pro-regular-svg-icons';

export const far: IconDefinition[] = [
    faCircleInfo,
    faCommentDots,
    faLightbulb,
    faCheckSquare,
    faSquare,
    faCircle1,
    faCircle2,
    faCircle3
]; 