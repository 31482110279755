import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@core';
import { RoutingStrategy } from '@vedrai/vedrai-core';
import { AccessDeniedComponent, MainLayoutComponent, NotFoundComponent } from '@layout';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { preload: true, menu: true, moduleTitle: 'Dashboard' }
      },
      {
        path: 'benchmark',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/benchmark/benchmark.module').then(m => m.BenchmarkModule),
        data: { preload: true, menu: true, moduleTitle: 'menu.benchmark' }
      },
      // {
      //   path: 'targets',
      //   canActivate: [AuthGuard],
      //   loadChildren: () => import('./pages/targets/targets.module').then(m => m.TargetsModule),
      //   data: { preload: true, menu: true, moduleTitle: 'menu.targets' }
      // },
      {
        path: 'examine',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/examine/examine.module').then(m => m.ExamineModule),
        data: { preload: true, menu: true, moduleTitle: 'menu.examine', group: 'examine' }
      },
      {
        path: 'report',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
        data: { preload: true, menu: true, moduleTitle: 'Report' }
      },
      {
        path: 'scenarios-flow',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/scenarios-flow/scenarios-flow.module').then(m => m.ScenariosFlowModule),
        data: { preload: true, menu: true, moduleTitle: 'menu.scenarios-flow', group: 'strategies' }
      }
    ]
  },
  {
    path: 'access',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/access/access.module').then(m => m.AccessModule),
    data: { preload: true, access: true }
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
    data: { public: true }
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
    data: { public: true }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    enableTracing: false,
    preloadingStrategy: RoutingStrategy,
    relativeLinkResolution: 'legacy',
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'top',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule],
  providers: [RoutingStrategy]
})
export class AppRoutingModule { }
