import { Moment } from "moment";
import { PeriodType, PluralPeriodType, RecursivlyPeriodType, ShortPeriodType } from "../period-types.model";
import { Period, IPeriod } from "../period.model";
import { AddStaticsToInstance } from "../periods.factory";

@AddStaticsToInstance
export class Year extends Period implements IPeriod {

    public static periodType: PeriodType = 'year';
    public static shortPeriodType: ShortPeriodType = 'Y';
    public static pluralPeriodType: PluralPeriodType = 'years';
    public static recursivlyPeriodType: RecursivlyPeriodType = 'yearly';

    public static icon: string[] = ['fas', 'calendar-days'];

    get startYear() {
        return +this.start.fullDate;
    }
    get endYear() {
        return +this.end.fullDate;
    }

    get year() {
        return +this.start.fullDate;
    }

    constructor(startDate: string, endDate?: string) {
        super(startDate, endDate);

        this.fetchFullDate(this.getFullFormat);
        this.fetchShortDate(this.getShortFormat);
    }

    getFullFormat = (date: Moment) => date.format('yyyy');
    getShortFormat = (date: Moment) => date.format('yy');

    getYearExtremesDays() {
        return {
            start: this.start.momentDate.startOf('year').format('yyyy-MM-DD'),
            end: this.end.momentDate.endOf('year').format('yyyy-MM-DD')
        }
    }
}