import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { Location } from '@angular/common';

import { map } from 'rxjs/operators';
import { RequestService } from '@vedrai/vedrai-core';
import {
    VariableTimeSeries, VariableExam,
    Filters, PeriodType,
    VariableTypes, IVariableCode, Variable, Side
} from '@models';
import { getPeriodSymbol } from '../../utilities/dates';

@Injectable({
    providedIn: 'root'
})
export class VariablesService {

    $kpis: Observable<any>;
    $variables: Observable<any>;

    constructor(private requestService: RequestService, private location: Location) {
    }

    getVariables(type: VariableTypes = null) {
        return this.requestService.execRequest('get', `monitoring/${type}`, {
            params: { ordering: 'display_name' }
        }).pipe(map((variables) => variables.map((variable) => new Variable(variable, type))));
    }

    getVariable(variableId: string, type: VariableTypes) {
        return this.requestService.execRequest('get', `monitoring/variables/${variableId}`)
            .pipe(map((variable) => new Variable(variable, type)));
    }

    getVariableTimeSeries(variable: Variable, variableId: string, period?: PeriodType) {
        const params = this.getPeriodParams(period);
        return this.requestService.execRequest('get', `monitoring/variables/${variableId}/timeseries`, { params })
            .pipe(map((timeSeries) => new VariableTimeSeries(variable, timeSeries)))
    }

    getVariableTechAnalysis(variableId: string, period?: PeriodType) {
        const params = this.getPeriodParams(period);
        return this.requestService.execRequest('get', `monitoring/variables/${variableId}/tech_analysis`, { params })
    }

    getVariableTechAnalysisPreview(variableId: string, period?: PeriodType) {
        const params = this.getPeriodParams(period);
        return this.requestService.execRequest('get', `monitoring/variables/${variableId}/tech_analysis/preview`, { params })
    }

    getAnalysis(exam: Side | VariableExam) {

        const { variable, id, selectedPeriod } = exam;
        return forkJoin([
            this.getVariableTimeSeries(variable, id, selectedPeriod),
            this.getVariableTechAnalysis(id, selectedPeriod),
            this.getVariableTechAnalysisPreview(id, selectedPeriod)
        ]);
    }

    getSidesAnalysis(left: Side, right: Side) {

        return forkJoin([
            this.getAnalysis(left),
            this.getAnalysis(right)
        ])
    }

    getImportantVariables(variableId: string) {
        return this.requestService.execRequest('get', `monitoring/variables/${variableId}/importat_variables`)
    }

    getFilters(variableId: string) {
        return this.requestService.execRequest('get', `monitoring/variables/${variableId}/tags`)
            .pipe(map((filter) => new Filters(filter)));
    }

    // usato in questions
    getForecastFilters(variableId: string) {
        return this.requestService.execRequest('get', `monitoring/variables/${variableId}/tags`, {
            params: { category_type: 'forecast' }
        }).pipe(map((filter) => new Filters(filter)));
    }

    /**
     * Form parsing url param and get id and type from a code
     * @param code string like this 'k-12' | 'v-19' where first letter is type and second is a variableId
     * @returns array that contains type and id
     */
    parseCode(code): IVariableCode {
        const noRes: IVariableCode = { type: 'kpis', id: 'none' };
        if (!code || typeof code == 'number') {
            return noRes;
        }
        const res = code.split('-');
        const [type, id] = res;
        if (res.length != 2 || (type != 'k' && type != 'v')) {
            return noRes;
        }

        return { type: type == 'k' ? 'kpis' : 'variables', id };
    }

    updateUrl(type: VariableTypes, variableId: string) {
        this.location.replaceState(`/examine?code=${this.getShortType(type)}-${variableId}`);
    }

    updateComparationUrl(left: Side, right: Side) {

        const leftVariableId = left.selectedFilter?.id || left.variable?.id || 'none';
        const rightVariableId = right.selectedFilter?.id || right.variable?.id || 'none';

        this.location.replaceState(`/examine/${this.getShortType(left.type)}-${leftVariableId}/${this.getShortType(right.type)}-${rightVariableId}`);
    }

    private getShortType(type: VariableTypes) {
        return type == 'kpis' ? 'k' : 'v'
    }

    private getPeriodParams(period: PeriodType) {
        const params: any = {};
        if (period) {
            params.analysis_period = getPeriodSymbol(period);
        }
        return params
    }

}