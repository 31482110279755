import { ITable } from "@vedrai/vedrai-ui";
import { TableStickyColumn } from "../table-sticky/table-sticky-config.model";
import {
    DirectionTypes, ITableRowGroupConfig,
    ITableRowGroupConfigWrapper,
    TableRowGroupConfig
} from "./table-row-group-config.models";


export interface ITableRowGroup extends ITable {
    groupBy: string | Function;
}

export class TableRowGroup implements ITableRowGroup {
    isLoading: boolean;
    configs: ITableRowGroupConfig;
    groupBy: string | Function;
    groupOrderDirection: DirectionTypes;
    orderBy?: Function;

    get data(): any {
        return this.configs.data || [];
    }

    set data(data: any) {
        this.setTableData(data);
    }

    private storedGroupedRows: any;

    constructor(tableRowConfigs: ITableRowGroupConfigWrapper, isLoading: boolean = false) {
        const { configs, presentationConfigs = {}, groupBy, orderBy, groupOrderDirection } = tableRowConfigs;

        this.configs = this.composeTableConfigs(configs, presentationConfigs);

        this.isLoading = isLoading;

        this.groupBy = groupBy;
        this.orderBy = orderBy;
        this.groupOrderDirection = groupOrderDirection;
    }

    composeTableConfigs(configs: ITableRowGroupConfig, presentationConfigs: any) {
        configs.columns = { ...configs, columns: configs.columns || [] }.columns.map((column) => {
            if (column.id && presentationConfigs[column.id]) {
                return new TableStickyColumn({ ...column, ...presentationConfigs[column.id] })
            }
            return new TableStickyColumn(column);
        });
        return new TableRowGroupConfig(configs);
    }

    filterRows(searchText: string, filterBy: string) {
        if (!searchText) {
            this.configs.data = this.storedGroupedRows;
            return;
        }

        const uppercaseSearchText = searchText.toUpperCase();
        let filteredGropedRows = {};

        this.configs.groupRowKeys.forEach(key => {
            filteredGropedRows[key] = this.storedGroupedRows[key].filter((row) =>
                row[filterBy].toUpperCase().startsWith(uppercaseSearchText)
            );
        });

        this.configs.data = filteredGropedRows;
    }

    private setTableData(data: any[]) {
        const groupsRows = this.getGroupsRows(data);
        this.storedGroupedRows = { ...groupsRows }

        this.configs.data = groupsRows;
        this.configs.groupRowKeys = this.groupRowKeys(groupsRows);

        if (this.configs.collapseRowGroups && !this.configs.rowGroupKeysCollapsed)
            this.configs.rowGroupKeysCollapsed = this.getRowGroupKeysCollapsed(this.configs.groupRowKeys)
    }

    private getGroupsRows(data: any[]) {
        if (!Array.isArray(data) || !data.length) return {};
        let groupsRows = {}, groupRowKey;

        data.forEach((row) => {
            groupRowKey = this.getGroupRowKey(this.groupBy, row);

            groupsRows[groupRowKey] ??= [];
            groupsRows[groupRowKey].push(row);
        });

        groupsRows = this.orderRowGroups(groupsRows);

        return groupsRows;
    }

    private orderRowGroups(groupsRows: any) {
        if (!this.orderBy) return groupsRows;

        const groupRowKeys = Object.keys(groupsRows);
        groupRowKeys.forEach(key => {
            groupRowKeys[key] = groupsRows[key].sort(this.orderBy);
        });

        return groupsRows;
    }

    private groupRowKeys(groupsRows: any) {
        const groupRowKeys = Object.keys(groupsRows);

        groupRowKeys.sort(this.orderGroupRowKeys(this.groupOrderDirection))

        return groupRowKeys;
    }

    private getGroupRowKey(groupBy: Function | string, row: any) {
        return typeof groupBy == 'string' ? row[groupBy] : groupBy(row);
    }

    private orderGroupRowKeys(direction: DirectionTypes) {
        if (!direction) return () => null;
        return (a, b) => (direction == 'DESC' ? a < b : a > b) ? 1 : -1;
    }

    private getRowGroupKeysCollapsed(groupsRows: any) {
        return Object.keys(groupsRows).reduce((res, key) => (res[key] = false, res), {});
    }

}