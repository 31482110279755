<div class="table-container">

    <div class="sticky-table row-group-table" [class]="isCompact ? 'card-table-responsive' : 'table-responsive'"
        aria-live="polite" [ngStyle]="{'min-height': inLoading ? '40rem' : 'auto'}">
        <table #rowGroupTable class="table" [class.card-list-table]="isCompact"
            [class]="tableClass || 'table-stripped'">
            <thead *ngIf="!headerTemplate" [class]="theadClass || 'thead-light'">
                <tr>
                    <th scope="col" *ngFor="let column of columns; let i = index" [attr.sticky]="column.sticky || null"
                        [class]="column.class" [style.min-width]="column.width ? column.width + 'px' : null"
                        [style]="column.sticky ?  '--data-sticky-offset:'+ column[column.sticky] : null"
                        [ngClass]="column.sticky ? column.sticky +'-sticky-th': null">
                        <div role="button" class="header-column" [class.sortable]="column.sortable"
                            [attr.tabindex]="column.sortable ? index : null"
                            [attr.aria-label]="((labels?.sort || 'Order column') | translate) + (column.sort ? (' ' + column.sort) : '')">
                            <span>
                                {{(labelPrefix + (column.label || column.id)) | translate}}
                            </span>
                            <span *ngIf="column.sortable && !!column.sort" class="sortable-icon"
                                [class.rotate]="column.sort == 'ASC'">
                            </span>
                            <span *ngIf="column.sortable && !column.sort" class="sortable-icon default">
                            </span>
                        </div>
                    </th>
                </tr>
            </thead>
            <ng-container *ngIf="headerTemplate">
                <ng-template *ngTemplateOutlet="headerTemplate; context: { $implicit: columns }">
                </ng-template>
            </ng-container>
            <tbody>
                <ng-container *ngFor="let key of groupRowKeys">
                    <tr class="row-group expanded-row">

                        <ng-container *ngFor="let col of columns; let i = index">
                            <td *ngIf="i==0" [attr.sticky]="col.sticky || null"
                                [ngClass]="col.sticky ? col.sticky +'-sticky-td': null"
                                [style]="col.sticky ?  '--data-sticky-offset:'+ col[col.sticky] : null">
                                <div [class.collapsable-cell]="collapseRowGroups">
                                    <ng-container *ngIf="!rowGroupTemplate">{{key}}</ng-container>
                                    <ng-container *ngIf="rowGroupTemplate">
                                        <ng-template [ngTemplateOutlet]="rowGroupTemplate"
                                            [ngTemplateOutletContext]="{key: key, value: rowGroups[key]}">
                                        </ng-template>
                                    </ng-container>
                                </div>
                            </td>

                            <td *ngIf="i != 0" [class.row-group-action-cell]="rowGroupActionTemplate"
                                [attr.sticky]="col.sticky || null"
                                [ngClass]="col.sticky ? col.sticky +'-sticky-td': null"
                                [style]="col.sticky ?  '--data-sticky-offset:'+ col[col.sticky] : null">
                                <ng-container *ngIf="i == columns.length -1">
                                    <ng-template [ngTemplateOutlet]="rowGroupActionTemplate"
                                        [ngTemplateOutletContext]="{key: key, value: rowGroups[key]}">
                                    </ng-template>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>

                    <ng-container *ngIf="!collapseRowGroups || !isRowGroupCollapsed[key]">
                        <ng-container *ngFor="let row of rowGroups[key]; let j = index">
                            <tr>
                                <ng-container *ngFor="let column of columns">

                                    <td [class]="column?.columnClass" [attr.sticky]="column.sticky || null"
                                        [style]="column.sticky ?  '--data-sticky-offset:'+ column[column.sticky] : null"
                                        [style.min-width]="column.width ? column.width + 'px' : null"
                                        [ngClass]="column.sticky ? column.sticky +'-sticky-td': null"
                                        [attr.data-title]="(labelPrefix + (column.label || column.id)) | translate">
                                        <span
                                            *ngIf="!column.templateContent && (row[column.id] | parse: column.transform) as value"
                                            [class]="column?.class">{{ column.translate ? (value | translate) : value
                                            }}</span>
                                        <ng-container *ngIf="column.templateContent">
                                            <ng-template [ngTemplateOutlet]="column.templateContent"
                                                [ngTemplateOutletContext]="{ $implicit: row, value: row[column.id], key: column.id, index: j }">
                                            </ng-template>
                                        </ng-container>
                                    </td>

                                </ng-container>
                            </tr>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <tr *ngIf="!rowGroups && !inLoading">
                    <div class="no-results">
                        {{(labels?.noResults || 'No results') | translate}}
                    </div>
                </tr>
            </tbody>
        </table>

    </div>

    <vedrai-local-loader [inLoading]="inLoading"></vedrai-local-loader>

</div>