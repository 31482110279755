import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skltn-checkboxes',
  templateUrl: './skltn-checkboxes.component.html'
})
export class SkltnCheckboxesComponent {

  @Input() rowsNumber: number = 5;

  rows: number[] = [];

  constructor() { }

  ngOnInit() {
    this.rows = [];
    for (let i = 0; i < this.rowsNumber; i++) {
      this.rows.push(i);
    }
  }

}
