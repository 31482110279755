import { ModuleWithProviders, NgModule } from "@angular/core";
import { AuthNotificationsService } from "./services/auth-notifications.service";
import { NotificationsService } from "./services/notifications.service";
import { WebsocketService } from "./services/websocket.service";
import { NotificationModalComponent } from './widgets/notification-modal/notification-modal.component';

@NgModule({
    imports: [NotificationModalComponent],
    exports: [NotificationModalComponent]
})
export class Ng2StompjsVendorModule {
    static forRoot(): ModuleWithProviders<Ng2StompjsVendorModule> {
        return {
            ngModule: Ng2StompjsVendorModule,
            providers: [
                AuthNotificationsService,
                NotificationsService,
                WebsocketService
            ]
        }
    }
}