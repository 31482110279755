import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skltn-toggles',
  templateUrl: './skltn-toggles.component.html'
})
export class SkltnTogglesComponent {

  @Input() rowsNumber: number = 5;
  @Input() list: boolean = false;

  rows: number[] = [];

  constructor() { }

  ngOnInit() {
    this.rows = [];
    for (let i = 0; i < this.rowsNumber; i++) {
      this.rows.push(i);
    }
  }

}
