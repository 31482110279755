<div class="modal-header">
    <h2 class="modal-title">{{ 'filters.available-filters' | translate }}</h2>
    <button aria-label="Close" class="close" data-dismiss="modal" (click)="close()">
        <span aria-hidden="true"> × </span>
    </button>
</div>
<div class="modal-body pb-0">
    <p class="text-sm pb-2 mb-2"> {{ 'filters.select-filter' | translate }}</p>
    <div class="flexw-between-center row">

        <div class="form-group col-12 col-md-5 mb-2">
            <vedrai-autocomplete [placeholder]="'filters.find-filter' | translate" (onSelection)="onSelectFilter($event)" name="search-filters"
                [options]="{ outputKey: 'id', labelKey: 'display_name'}" [showRemoveBtn]="false"
                [(ngModel)]="selectedFilterId" [autocompleteList]="flattedFilters" [loadOnFocus]="true">
                <div prepend class="input-group-prepend">
                    <span class="input-group-text">
                        <fa-icon [icon]="['fas', 'magnifying-glass']"></fa-icon>
                    </span>
                </div>
            </vedrai-autocomplete>
        </div>

        <ul ngbNav #nav="ngbNav" class="nav-pills d-none d-md-flex mb-2 mr-3" (navChange)="onModalityChange($event)">
            <li ngbNavItem ngbNavItem="simple">
                <a class="py-2 text-xs nav-link" ngbNavLink>
                    <fa-icon [icon]="['fas', 'sitemap']" class="mr-2"></fa-icon>
                    {{ 'filters.tree' | translate }}
                </a>
                <ng-template ngbNavContent>
                    <div class="filters-chart" echarts [options]="filtersTreeChart.chart"
                        (chartInit)="setChartsInstance($event)" (chartMouseDown)="onChartClick($event)">
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem ngbNavItem="composed">
                <a class="py-2 text-xs nav-link" ngbNavLink>
                    <fa-icon [icon]="['fas', 'grip-horizontal']" class="mr-2"></fa-icon>
                    {{ 'filters.composition' | translate }}
                </a>
                <ng-template ngbNavContent>
                    <div class="filters-chart" echarts [options]="filtersTreeMapChart.chart"
                        (chartInit)="setChartsInstance($event)" (chartClick)="onChartClick($event)">
                    </div>
                </ng-template>
            </li>
        </ul>

    </div>
    <div class="pb-2 d-none d-md-block" [ngbNavOutlet]="nav"></div>

    <div class="row">
        <div class="col-12">
            <div class="card filters-card min-height">
                <div class="flexw-start-center">
                    <span *ngFor="let filter of shortcutFilters" class="filter-badge">
                        <span class="badge badge-sm cursor-pointer" 
                            [ngClass]="filter.id == selectedFilter?.id ? 'badge-primary': 'badge-light-info'"
                            role="button" (click)="selectFilter(filter)">
                            {{filter.display_name}}
                            <fa-icon (click)="removeShortcut(filter)" [icon]="['fas', 'times']" class="ml-1"></fa-icon>
                        </span>
                    </span>
                    <span *ngIf="newShortcutFilter" class="filter-badge">
                        <span class="badge badge-sm badge-primary" role="button"
                            (click)="addShortcut(newShortcutFilter)" container="body" placement="top"
                            [ngbTooltip]="'filters.add-filters-to-list' | translate">
                            {{newShortcutFilter.display_name}}
                            <fa-icon [icon]="['fas', 'thumbtack']" class="ml-1"></fa-icon>
                        </span>
                    </span>
                    <span *ngIf="shortcutFilters?.length" class="filter-badge">
                        <span class="badge badge-sm badge-light-primary" role="button" (click)="clearShortcuts()">
                            {{ 'operations.deselect-all' | translate }}
                            <fa-icon [icon]="['fas', 'trash-alt']" class="ml-1"></fa-icon>
                        </span>
                    </span>
                    <span class="filter-badge">
                        <span class="badge badge-sm badge-light-primary" role="button" (click)="resetShortcuts()">
                            {{ 'operations.restore' | translate }}
                            <fa-icon [icon]="['fas', 'redo-alt']" class="ml-1"></fa-icon>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="modal-footer">
    <button class="btn btn-link m-0" (click)="close()">  {{ 'operations.cancel' | translate }} </button>
    <button class="btn btn-primary m-0 ml-auto" [disabled]="!selectedFilter" (click)="save()"> {{(newShortcutFilter ? 'operations.add' : 'operations.select') | translate}} </button>
</div>