import { deepClone } from "@vedrai/vedrai-core";

import { ITechAnalysis, ITechAnalysisPreview } from "../analysis/tech-analysis.model";
import { Filters } from "./filters.model";
import { ISensitivityVariable } from "./sensitivity-variables.model";
import { Variable } from "./variable.model";
import { PeriodType } from "../../dates/period-types.model";
import { VariableTimeSeries } from "../../echarts/time-series/variable-time-series.model";
import { ChartType } from "../../echarts/echarts-types.model";
import { VariableTypes } from "./variable-types";

export interface IVariableExam {
    variable: Variable;

    inLoading: boolean;
    updatesInLoading: boolean;

    techAnalysis: ITechAnalysis;
    techAnalysisPreview: ITechAnalysisPreview;
    sensitivityVariables?: ISensitivityVariable[];
    type: VariableTypes;
    timeSeries: VariableTimeSeries;
    chartType: ChartType;
    filters: Filters;
    selectedFilter: Filters;
}

export class VariableExam implements IVariableExam {
    variable: Variable;

    inLoading: boolean;
    updatesInLoading: boolean;

    techAnalysis: ITechAnalysis;
    techAnalysisPreview: ITechAnalysisPreview;
    sensitivityVariables?: ISensitivityVariable[];
    type: VariableTypes;
    timeSeries: VariableTimeSeries;
    chartType: ChartType;

    defaultPeriod: PeriodType;
    selectedPeriod: PeriodType;

    filters: Filters;
    selectedFilter: Filters;
    showFilters: boolean;

    get filterId() {
        return this.selectedFilter?.id;
    }

    get variableId() {
        return this.variable?.id;
    }

    get id() {
        return this.filterId || this.variableId;
    }

    get isValorized() {
        return !!this.timeSeries;
    }

    get hasTags() {
        return !!this.filters?.children?.length;
    }

    constructor(variable: Variable, period: PeriodType) {

        this.variable = variable;
        this.defaultPeriod = this.selectedPeriod = period;
        this.type = variable?.type || 'kpis';
        this.chartType = 'line';
        this.inLoading = true;
    }

    fetchVariable(variable: Variable) {
        this.variable = variable;
        if (variable) {
            this.type = variable.type;
        } else {
            this.reset();
        }
    }

    fetchFilters(filters: Filters) {
        this.filters = deepClone(filters);
        this.selectedFilter = this.findFilter(this.filters.children, this.variableId);
        this.showFilters = this.showFilters || !!this.selectedFilter;
    }

    fetchAnalysis([timeSeries, techAnalysis, techAnalysisPreview]) {
        this.techAnalysis = techAnalysis;
        this.timeSeries = timeSeries;
        this.techAnalysisPreview = techAnalysisPreview;
    }

    private reset() {
        this.timeSeries = null;
        this.chartType = 'line';
        this.resetLoaders();
        this.resetAnalysis();
        this.resetFilters();
    }

    resetLoaders() {
        this.inLoading = false;
        this.updatesInLoading = false;
    }

    resetAnalysis() {
        this.techAnalysis = null;
        this.techAnalysisPreview = null;
        this.sensitivityVariables = [];
    }

    resetFilters() {
        this.filters = null;
        this.selectedFilter = null;
    }

    private findFilter(filters: Filters[], id: string) {
        let stack = [...filters], filter: Filters, i = 0;
        while (stack.length > 0) {
            filter = stack.pop();
            if (filter.id == id) {
                return filter;
            }

            if (filter.children?.length) {
                for (i = 0; i < filter.children.length; i += 1) {
                    stack.push(filter.children[i]);
                }
            }
        }
        return null;
    }

}