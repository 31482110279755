<skltn-root>
  <ul class="list-group list-group-flush">
    <li *ngFor="let x of rows" class="list-group-item">
      <div class="skltn-toggle">
        <div class="skltn-toggle-caption">
          <div skltn-bone class="skltn-toggle-line"></div>
        </div>
        <div skltn-bone class="skltn-toggle-toggle" [rectRadius]="11"></div>
      </div>
    </li>
  </ul>
</skltn-root>