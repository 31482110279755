import { TableCollapsableConfig, ITableCollapsableConfigWrapper,
     TableCollapsableColumn, ITableCollapsableColumn } from "./table-collapsable-config.model";

export interface ITableCollapsable {
    rowClass?: Function | string;
}

export class TableCollapsable {
    
    isLoading: boolean;
    configs: TableCollapsableConfig;
    private rowClass: Function | string;

    get columns() {
        return this.configs.columns;
    }

    private set columns(columns: any[]) {
        this.configs.columns = columns;
    }
    
    set data(data: any[]) {
        this.setTableData(data);
    }

    get data(): any[] {
        return this.configs.data || [];
    }

    constructor(tableCollapsableConfigs: ITableCollapsableConfigWrapper, isLoading: boolean = false) {
        const { rowClass, configs, presentationConfigs = {} } = tableCollapsableConfigs;
        this.configs = new TableCollapsableConfig(configs);
        this.updateColumns(configs.columns, presentationConfigs);

        this.isLoading = isLoading;
        this.rowClass = rowClass;
    }

    updateTableColumns(columns: ITableCollapsableColumn[], presentationConfigs: any = {}) {
        this.updateColumns(columns, presentationConfigs);
        this.configs = new TableCollapsableConfig(this.configs);
    }

    private updateColumns(columns: any[], presentationConfigs: any = {}) {
        this.columns = columns.map((column) =>
            new TableCollapsableColumn(column, presentationConfigs[column.id])
        );
    }

    private setTableData(data: any[]) {
        this.configs.data = this.getRowClasses(data);
    }

    private getRowClasses(data: any[]) {
        if (!this.rowClass) return data;

        data.forEach((row) => {
            row.rowClass = this.getRowClass(this.rowClass, row);
        });
        return data;
    }

    private getRowClass(rowClass: Function | string, row: any) {
        return typeof rowClass == 'string' ? rowClass : rowClass(row);
    }

}