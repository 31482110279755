import { Component, OnInit } from '@angular/core';
import { FiltersTreeChart, FiltersTreeMapChart, Filters } from '@models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartsService } from '@vendors/modules/ngx-echarts';
import { deepClone } from '@vedrai/vedrai-core';
import { IOptionSelection } from '@vedrai/vedrai-ui';

export type FiltersChartTypes = FiltersTreeChart | FiltersTreeMapChart;

@Component({
    selector: 'app-variable-filters-modal',
    templateUrl: './variable-filters-modal.component.html'
})
export class VariableFiltersModalComponent implements OnInit {

    data: any;
    selectedFilter: Filters;
    selectedChart: FiltersChartTypes;
    selectedFilterId: string;
    flattedFilters: Filters[];
    filtersTreeChart: FiltersTreeChart;
    filtersTreeMapChart: FiltersTreeMapChart;

    shortcutFilters: Filters[];
    newShortcutFilter: Filters;

    get filters(): Filters {
        return this.data.filters;
    }

    constructor(private activeModalService: NgbActiveModal, private chartsService: ChartsService) {
    }

    ngOnInit() {
        const filtersTreeChart = this.chartsService.getChart('variables', 'filters-tree');
        this.filtersTreeChart = new FiltersTreeChart(filtersTreeChart, this.filters);
        const filtersTreeMapChart = this.chartsService.getChart('variables', 'filters-treemap');
        this.filtersTreeMapChart = new FiltersTreeMapChart(filtersTreeMapChart, this.filters);
        this.selectedChart = this.filtersTreeChart;
        this.initFilters(this.data);
    }

    setChartsInstance(instance: any) {
        this.selectedChart.setInstance(instance);
        this.selectedChart.initSelections(this.selectedFilter, this.shortcutFilters);
    }

    initFilters(data) {
        const { selectedFilter, shortcutFilters = [] } = data;
        this.shortcutFilters = deepClone(shortcutFilters);
        this.flattedFilters = this.flatFilters(this.filters.children);
        this.selectFilter(selectedFilter);
    }

    selectFilter(filter: Filters = null) {
        this.selectedFilter = filter;
        this.selectedChart.selectFilter(filter);
        this.selectedFilterId = filter?.display_name || '';

        if (filter) {
            const shortcutFilter = this.shortcutFilters.find((shortcutFilter) => shortcutFilter.id == filter.id);
            this.newShortcutFilter = shortcutFilter ? null : filter;
        } else {
            this.newShortcutFilter = null
        }
    }

    addShortcut(filter: Filters) {
        if (!filter) return;

        const shortcutFilter = this.shortcutFilters.find((shortcutFilter) => shortcutFilter.id == filter.id);
        if (!!shortcutFilter) return;

        this.shortcutFilters.push(filter);
        this.selectedChart.updateHighlight(this.shortcutFilters);
        this.newShortcutFilter = null;
    }

    removeShortcut(filter: Filters) {
        if (!filter) return;

        if (filter.id == this.selectedFilter?.id) {
            this.selectFilter(null);
        }
        const shortcutFilterIndex = this.shortcutFilters.findIndex((shortcutFilter) => shortcutFilter.id == filter.id);
        this.shortcutFilters.splice(shortcutFilterIndex, 1);
        this.selectedChart.updateHighlight(this.shortcutFilters);
    }

    clearShortcuts() {
        this.shortcutFilters = [];
        this.selectFilter();
        this.selectedChart.updateHighlight(this.shortcutFilters);
    }

    resetShortcuts() {
        this.shortcutFilters = deepClone(this.filters.children);
        this.selectFilter();
        this.selectedChart.updateHighlight(this.shortcutFilters);
    }

    onModalityChange(event) {
        this.selectedChart = event.nextId == 'simple' ? this.filtersTreeChart : this.filtersTreeMapChart;
    }

    onSelectFilter(selection: IOptionSelection) {
        this.selectFilter(selection.value ? selection.option : null);
    }

    onChartClick({ data: filter }) {
        if (!filter) return;
        this.selectedFilterId = null;
        this.newShortcutFilter = null;

        if (filter.filter_name != 'Totale') { // TODO change with a code D:
            this.selectedFilter = this.selectedFilter?.id == filter.id ? null : filter;
        } else {
            this.selectedFilter = null;
        }

        if (this.selectedFilter) {
            const { id, display_name } = this.selectedFilter;
            this.selectedFilterId = display_name;
            const shortcutFilter = this.shortcutFilters.find((shortcutFilter) => shortcutFilter.id == id);
            if (!shortcutFilter) {
                this.newShortcutFilter = this.selectedFilter;
            }
        }

        this.selectedChart.selectFilter(this.selectedFilter);
    }

    close() {
        this.activeModalService.close();
    }

    save() {
        this.activeModalService.close({
            filter: this.selectedFilter,
            shortcutFilters: this.shortcutFilters
        });
    }

    private flatFilters(filters: Filters[]) {
        return filters.reduce((res, filter) => {
            res.push(filter);
            if (filter.children) {
                res = res.concat(this.flatFilters(filter.children));
            }
            return res;
        }, []);
    }
}