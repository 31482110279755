import { NgForOf } from '@angular/common';
import { Directive, Host } from '@angular/core';

@Directive({
    selector: '[ngForTrackByIndex]',
})
export class TrackByIndexDirective<T> {
    constructor(@Host() private readonly ngFor: NgForOf<T>) {
        this.ngFor.ngForTrackBy = (index: number, item: T) => index;
    }
}