import { Injectable } from "@angular/core";
import { InjectableRxStompConfig } from "@stomp/ng2-stompjs";
import { environment } from "@env/environment";
import notificationConfigs from '@configs/notifications/notifications.json';
import { RequestService } from "@vedrai/vedrai-core";
import { catchError, retry } from "rxjs/operators";
import { of, Subject } from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class AuthNotificationsService {

    configs: InjectableRxStompConfig;
    sessionId: string;
    queueId: string;
    isConnectionAvailable$: Subject<boolean>;
    private _isConnectionAvailable: boolean = false;

    get broker() {
        return environment.broker;
    }

    get debug() {
        return this.broker.debug;
    }

    get url() {
        return this.broker.url;
    }

    get topic() {
        return this.broker?.topic;
    }

    get virtualHost() {
        return this.broker.virtualHost;
    }

    get isConnectionAvailable() {
        return this._isConnectionAvailable;
    }

    set isConnectionAvailable(available: boolean) {
        this.isConnectionAvailable$.next(available);
        this._isConnectionAvailable = available;
    }

    constructor(private requestService: RequestService) {
        this.isConnectionAvailable$ = new Subject();
        this.configs = notificationConfigs.configs;
        this.configs.brokerURL = this.url;
        this.sessionId = this.generateSessionId(10);

        if (this.debug) {
            this.configs.debug = (msg: string) => console.warn(msg);
        }
    }

    initAuthentication(userId: number) {
        this.requestService.execRequestExt('post', environment.urlApiNotifications + '/auth_notifications', {
            user_id: `${userId}`,
            session_id: this.sessionId
        }).subscribe(({ passCode }) => this.consumeConfigs(passCode, userId));
    }

    logoutNotificationsBroker(userId: number) {
        return this.requestService.execRequestExt('post', environment.urlApiNotifications + '/logout_notifications', {
            user_id: userId,
            session_id: this.sessionId
        }).pipe(retry(4), catchError(() => of({})));
    }

    private consumeConfigs(passCode: string, userId: number) {
        this.queueId = `${userId}_${this.sessionId}`;
        this.configs.connectHeaders = {
            login: `${userId}_${this.virtualHost}`,
            passcode: passCode,
            host: this.virtualHost
        };
        this.isConnectionAvailable = true;
    }

    private generateSessionId(length: number) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
}